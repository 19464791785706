import { domReady } from "../../utils";
import DualListbox from 'dual-listbox'

const selectTeam = () => {
  const selectEl = document.querySelector('#dualListUsers')
  if (selectEl) {
    const dualListbox = new DualListbox(selectEl, {
      availableTitle: 'Usuários Disponíveis',
      selectedTitle: 'Usuários Selecionados',
      addButtonText: 'Adicionar <i class="fa fa-angle-right"></i>',
      removeButtonText: '<i class="fa fa-angle-left"></i> Remover',
      addAllButtonText: 'Todos <i class="fa fa-angle-double-right"></i>',
      removeAllButtonText: '<i class="fa fa-angle-double-left"></i> Nenhum',
    })
    dualListbox.search.classList.add('form-control');
    dualListbox.search.setAttribute('placeholder', 'Buscar usuário');
  }
}

export default domReady(selectTeam);
