// import compose from 'lodash/fp/compose'
// import { domReady } from '../../utils'

export const enableSelect = el => el.removeAttribute("disabled");
export const disableSelect = el => el.setAttribute("disabled", "disabled");

export const clearSelectElTarget = (el, msg) => {
  clearEl(el);
  disableSelect(el);
  el.insertAdjacentHTML("beforeend", `<option value="">${msg}</option>`);
};

export const clearEl = el => (el.innerHTML = "");

// export default domReady(compose(enableSelect, disableSelect))
