import { Fancybox } from "@fancyapps/ui";
import { domReady } from "../utils";

const initFancybox = () => {
  Fancybox.bind("[data-fancybox]", {
    // Your options go here
  });
};

export default domReady(initFancybox);
