import axios from "axios";

export const getIntegrationParamToPublication = () => {
  const publicationRows = $(".publication_row").children("tr");

  let newArray = [];
  if (publicationRows) {
    publicationRows.map((element) => {
      let code = $(publicationRows[element]).find(".publication_code");
      let name = $(publicationRows[element]).find(".publication_name");
      let integrationClientId = $(code).attr("data-integration-id");
      let dependentCode = $(code).attr("data-dependent-code");
      let url = $(code).attr("data-url");
      let searchParam = $(code).attr("data-param");
      let valueField = $(publicationRows[element]).find(".publication_value");
      let valueSelected = $(publicationRows[element])
        .find(".publication_value")
        .attr("data-value");

      if (url) {
        if (searchParam) {
          let publicationCodeField = $(`.publication_value_${dependentCode}`);
          console.log(publicationCodeField);
          if (publicationCodeField.attr("data-value")) {
            console.log(publicationCodeField.attr("data-value"));
            let make_id = null;
            if (
              publicationCodeField.hasClass("publication_value_vehicle_model")
            ) {
              make_id = `make_id=${$("#publication_param_vehicle_brand").attr(
                "data-value"
              )}`;
            }

            getParamsById(
              `${url}?integration_client_id=${integrationClientId}&${searchParam}=${publicationCodeField.attr(
                "data-value"
              )}&${make_id}`,
              valueField,
              valueSelected
            );
          }
          publicationCodeField.on("change", function (e) {
            let make_id = null;
            if (
              publicationCodeField.hasClass("publication_value_vehicle_model")
            ) {
              make_id = `make_id=${$(
                "#publication_param_vehicle_brand"
              ).val()}`;
            }
            getParamsById(
              `${url}?integration_client_id=${integrationClientId}&${searchParam}=${e.target.value}&${make_id}`,
              valueField,
              valueSelected
            );
          });
        } else {
          getParamsById(
            `${url}?integration_client_id=${integrationClientId}`,
            valueField,
            valueSelected
          );

          // axios.get(url).then(function (response) {
          //   valueField.html("");
          //   let option = ``;
          //   option = `<option value> Selecione</option >`;
          //   if (response.data) {
          //     response.data.forEach((el) => {
          //       const selected = valueSelected == el.id;
          //       if (selected) {
          //         option +=
          //           "<option selected=" +
          //           selected +
          //           ' value ="' +
          //           el.id +
          //           '">' +
          //           el.name +
          //           "</option >";
          //       } else {
          //         option +=
          //           "<option value='" + el.id + '">' + el.name + "</option >";
          //       }
          //     });
          //     valueField.append(option);
          //   }
          // });
        }
      }
    });
  }
};

const getParamsById = async (url, field, paramSelected) => {
  const response = await axios.get(url);

  field.html("");
  let option = ``;
  option = `<option value> Selecione</option >`;
  field.append(option);
  if (response.data) {
    response.data.forEach((el) => {
      console.log(el);
      console.log(paramSelected);
      let selected = false;
      if (el.name) {
        selected =
          paramSelected == el.id ||
          paramSelected == el.name.toString().toLowerCase();
      } else {
        selected = paramSelected == el.id;
      }
      var newOption = new Option(el.name, el.id, selected, selected);
      field.append(newOption);
      // if (selected) {
      //   field.append(
      //     "<option selected=" +
      //       selected +
      //       ' value ="' +
      //       el.id +
      //       '">' +
      //       el.name +
      //       "</option >"
      //   );
      // } else {
      //   field.append("<option value='" + el.id + '">' + el.name + "</option >");
      // }
    });

    // field.html(option);
  }
};

$(document).ready(function () {
  getIntegrationParamToPublication();
});
