import { domReady } from "../../utils";
import DualListbox from "dual-listbox";

const selectRole = () => {
  const selectEl = document.querySelector("#dualListRoles");
  if (selectEl) {
    const dualListbox = new DualListbox(selectEl, {
      availableTitle: "Permissões Disponíveis",
      selectedTitle: "Permissões Selecionados",
      addButtonText: 'Atribuir <i class="fa fa-angle-right"></i>',
      removeButtonText: '<i class="fa fa-angle-left"></i> Remover',
      addAllButtonText: 'Todos <i class="fa fa-angle-double-right"></i>',
      removeAllButtonText: '<i class="fa fa-angle-double-left"></i> Nenhum',
    });
    dualListbox.search.classList.add("form-control");
    dualListbox.search.setAttribute("placeholder", "Buscar permissão");
  }
};

export default domReady(selectRole);
