export default {
  state: () => ({
    expenses: [
      { id: null, description: "Lataria", amount: 0, value: 0 },
      { id: null, description: "Pneus", amount: 0, value: 0 },
      { id: null, description: "Outros", amount: 0, value: 0 }
    ]
  }),
  mutations: {
    setExpenseId(state, { index, value }) {
      state.expenses[index].id = value;
    },
    setExpenseAmount(state, { index, value }) {
      state.expenses[index].amount = value;
    },
    setExpenseValue(state, { index, value }) {
      state.expenses[index].value = value;
    },
    setExpenseForm(state, payload) {
      state.expenses = payload;
    }
  },
  actions: {}
};
