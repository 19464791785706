import { domReady } from "../utils";

const verifyZipCode = () => {
  $('.cep_search').keyup(function () {
    const cep = $(this).val()
    console.log("Search cep")
    if (cep.length == 9) {
      $.get("https://api.carhoo.com.br/find_by_cep/" + cep, {}, function (data) {
        if (data != 0) {
          $('.neighborhood_search').val(data.bairro)
          $('.street_search').val(data.street)
          $('.state_search').val(data.state)
          $('.city_search').val('')
          $('.city_search').html('')
          $('.city_search').append("<option value=\"\">Selecione a Cidade</option>")
          data.cities.forEach(function (element) {
            var newOption = new Option(element.name, element.id, false, false);
            // Append it to the select
            $('.city_search').append(newOption)
          });
          $('.city_search').val(data.city)
        } else {
          console.log('cep error')
        }
      })
    }
  });
}

export default domReady(verifyZipCode);
