import Vue from "vue";

Vue.component("StatCard", () => import("./StatCard.vue"));
Vue.component("SimpleStatCard", () => import("./SimpleStatCard.vue"));
Vue.component("StatCardSingle", () => import("./StatCardSingle.vue"));
Vue.component("StatCardPercent", () => import("./StatCardPercent.vue"));
Vue.component("SimpleStatCardSingle", () =>
  import("./SimpleStatCardSingle.vue")
);
Vue.component("StatGroup", () => import("./StatGroup.vue"));
