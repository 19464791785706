import { domReady } from "../../utils";
import axios from "axios";
import {
  enableSelect,
  disableSelect,
  clearSelectElTarget,
  clearEl,
} from "./concerns";

// const dataToken = document.querySelector("#token");
// if (dataToken) {
//   axios.defaults.headers.common["Authorization"] = dataToken.dataset.token;
// }

const main = () => {
  const kindStatusEl = document.querySelector("#kmmtc_kind_status");
  const kindEl = document.querySelector("#kmmtc_kind");
  const makeEl = document.querySelector("#kmmtc_make_id");
  const modelEl = document.querySelector("#kmmtc_model_id");
  const trimEl = document.querySelector("#kmmtc_trim_id");
  const colorEl = document.querySelector("#kmmtc_color_id");

  if (!kindStatusEl && !kindEl && !makeEl && !modelEl && !trimEl && !colorEl) {
    return;
  }

  const BASE_URL = "searches";

  const init = () => {
    if (kindEl.value === "") {
      clearSelectElTarget(makeEl, "Selecione primeiro o Tipo");
      disableSelect(makeEl);
    }

    if (kindEl.value === "") {
      disableSelect(kindStatusEl);
    }

    if (makeEl.value === "") {
      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);
    }

    if (modelEl.value === "") {
      clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      disableSelect(trimEl);
    }

    if ([...trimEl.options].filter((el) => el.value !== "").length > 0) {
      enableSelect(trimEl);
    } else {
      disableSelect(trimEl);
      clearSelectElTarget(trimEl, "Não disponível");
    }

    if ([...colorEl.options].filter((el) => el.value !== "").length > 0) {
      enableSelect(colorEl);
    } else {
      disableSelect(colorEl);
      clearSelectElTarget(colorEl, "Não disponível");
    }

    kindHandler();
  };

  const kindHandler = () => {
    kindStatusEl.addEventListener("change", function () {
      let kind_id = parseInt(this.value);
      let make_id = kindStatusEl.dataset.make;
      let event = new Event("change");

      if (kind_id == 1) {
        makeEl.value = make_id;
        makeEl.dispatchEvent(event);
      }

      // Dispatch it.
      makeHandler();
      console.log("mudei kindStatusEl");
    });

    kindEl.addEventListener("change", function () {
      let kind_id = this.value;

      clearSelectElTarget(makeEl, "Selecione primeiro o Tipo");
      disableSelect(makeEl);

      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);

      clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      disableSelect(trimEl);

      clearSelectElTarget(colorEl, "Selecione primeiro o Modelo");
      disableSelect(colorEl);

      enableSelect(kindStatusEl);

      if (kind_id) {
        axios
          .get(`${BASE_URL}/makes?q[kind_eq]=${kind_id}&only_dealer=true`)
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(makeEl);

            // Populando OPTIONS
            if (response.data.makes.length > 0) {
              enableSelect(makeEl);

              makeEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Marca</option>`
              );

              response.data.makes.map((current_data) => {
                makeEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${
                    current_data.id
                  }">${current_data.name.toUpperCase()}</option>`
                );
              });
            } else {
              clearSelectElTarget(makeEl, "Não disponível");
              clearSelectElTarget(modelEl, "Não disponível");
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(makeEl, "Selecione primeiro o Tipo");
      }
    });
    makeHandler();
  };

  const makeHandler = () => {
    makeEl.addEventListener("change", function () {
      let make_id = this.value;

      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);

      clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      disableSelect(trimEl);

      clearSelectElTarget(colorEl, "Selecione primeiro o Modelo");
      disableSelect(colorEl);

      refetchModels(make_id);
    });

    kindStatusEl.addEventListener("change", function () {
      refetchModels(makeEl.value);
    });

    modelHandler();
  };

  function refetchModels(make_id) {
    if (make_id) {
      let search_models_url = `${BASE_URL}/models?q[make_id_eq]=${make_id}`;

      if ($("kmmtc_kind_status")) {
        if ($("#kmmtc_kind_status").val() == "newed") {
          search_models_url = `${BASE_URL}/models?q[make_id_eq]=${make_id}&q[kind_eq]=1`;
        }
      }

      axios
        .get(
          // `${BASE_URL}/models?q[type_eq]=New::Model&q[make_id_eq]=${make_id}`
          search_models_url
        )
        .then(function (response) {
          // Limpando OPTIONS
          clearEl(modelEl);

          // Populando OPTIONS
          if (response.data.models.length > 0) {
            enableSelect(modelEl);

            modelEl.insertAdjacentHTML(
              "beforeend",
              `<option value="">Selecione o Modelo</option>`
            );

            response.data.models.map((current_data) => {
              modelEl.insertAdjacentHTML(
                "beforeend",
                `<option value="${current_data.id}">${current_data.name}</option>`
              );
            });
          } else {
            clearSelectElTarget(modelEl, "Não disponível");
            clearSelectElTarget(trimEl, "Não disponível");
          }
        });
    } else {
      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
    }
  }

  const modelHandler = () => {
    modelEl.addEventListener("change", function () {
      let model_id = this.value;

      clearSelectElTarget(colorEl, "Selecione primeiro a Versão");
      disableSelect(colorEl);

      if (model_id) {
        axios
          .get(
            // `${BASE_URL}/trims?q[type_eq]=New::Trim&q[model_id_eq]=${model_id}`
            `${BASE_URL}/trims?q[model_id_eq]=${model_id}`
          )
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(trimEl);

            // Populando OPTIONS
            if (response.data.trims.length > 0) {
              enableSelect(trimEl);

              trimEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Versão</option>`
              );

              response.data.trims.map((current_data) => {
                trimEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      }
    });

    trimHandler();
  };

  const trimHandler = () => {
    trimEl.addEventListener("change", function () {
      let trim_id = this.value;

      if (trim_id) {
        axios
          // .get(`${BASE_URL}/trim_colors?id=${trim_id}`)
          .get(`${BASE_URL}/colors`)
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(colorEl);

            // Populando OPTIONS
            if (response.data.colors.length > 0) {
              enableSelect(colorEl);

              colorEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Cor</option>`
              );

              response.data.colors.map((current_data) => {
                colorEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(colorEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(colorEl, "Selecione primeiro a Versão");
      }
    });
  };

  init();
};

export default domReady(main);
