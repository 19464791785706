import { select, options } from "tail.select/js/tail.select-es6";

export default document.addEventListener("DOMContentLoaded", function() {
  select(".tailSelect", {
    /* Your Options */
    search: true,
    locale: "pt_BR",
    width: "100%"
  });
});
