export const getIntegrationParam = () => {
  const integrationParamRows = $(".integration_param_row");
  let newArray = [];

  integrationParamRows.map((element) => {
    if (
      $(integrationParamRows[element]).find(".integration_param_code").val()
    ) {
      newArray.push({
        code: $(integrationParamRows[element])
          .find(".integration_param_code")
          .val(),
        name: $(integrationParamRows[element])
          .find(".integration_param_name")
          .val(),
      });
    }
  });

  let x;
  $(".dependent_code_integration_param").html("");
  $(".dependent_code_integration_param").append(
    "<option value> Selecione campo dependente </option >"
  );

  newArray.forEach((el) => {
    let option = '<option value ="' + el.code + '">' + el.name + "</option >";
    $(".dependent_code_integration_param").append(option);
  });

  integrationParamRows.map((element) => {
    let dependent_code = $(integrationParamRows[element])
      .find(".integration_param_code")
      .attr("data-dependent-code");
    if (dependent_code) {
      console.log(
        $(integrationParamRows[element]).find(
          `.dependent_code_integration_param`
        )
      );
      $(integrationParamRows[element])
        .find(`.dependent_code_integration_param`)
        .val(dependent_code);
    }
  });
};

$(document).ready(function () {
  getIntegrationParam();
  $(".nestedDataIntegrationParams").on(
    "cocoon:after-insert",
    function (e, insertedItem) {
      getIntegrationParam();
    }
  );
});
