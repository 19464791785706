import Vue from "vue";
import Vuex from "vuex";
import evaluationForm from "./evaluation/evaluationForm";
import expenseForm from "./evaluation/expenseForm";
import vehicleForm from "./evaluation/vehicleForm";
import clientForm from "./evaluation/clientForm";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    evaluationForm,
    expenseForm,
    vehicleForm,
    clientForm
  }
});
