import { domReady } from "../../utils";
import axios from "axios";
import {
  enableSelect,
  disableSelect,
  clearSelectElTarget,
  clearEl
} from "./concerns";

const main = () => {
  if($("#lead_dealer_id").val()){
    getMakeIdByDealer($("#lead_dealer_id").val())
  }

  $("#lead_dealer_id").on("change", function() {
    console.log("CHANGEEE")
    // if($("#lead_dealer_id").val()){
      getMakeIdByDealer($("#lead_dealer_id").val())
    // }
  })

  function getMakeIdByDealer(id){
    $.get("/api/v1/searches/dealer_make?id="+id, {}, function (data) {
      if(data) {
        $(".nested_make_id").val(data.id)
        $("#make_id_hidden_input").val(data.id);
        if (data.id) {
          axios
            .get(`${BASE_URL}/models?q[make_id_eq]=${data.id}`)
            .then(function(response) {
              // Limpando OPTIONS
              clearEl(modelEl);
  
              // Populando OPTIONS
              if (response.data.models.length > 0) {
                enableSelect(modelEl);
                $(".nested_model_id").map(item => {
                  $($(".nested_model_id")[item]).html("")
                  $($(".nested_model_id")[item]).attr("disabled", false)
                  $($(".nested_model_id")[item]).append(`<option value="">Selecione o Modelo</option>`)
                })
                
                response.data.models.map(current_data => {
                  $(".nested_model_id").map(item => {
                    $($(".nested_model_id")[item]).append(`<option value="${current_data.id}">${current_data.name}</option>`)
                  })
                });
              } else {
                clearSelectElTarget(modelEl, "Não disponível");
                clearSelectElTarget(trimEl, "Não disponível");
              }
            });
        } else {
          clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
        }
      }
    })
  }
  
  const makeEl = document.querySelector(".nested_make_id");
  const modelEl = document.querySelector(".nested_model_id");
  const trimEl = document.querySelector(".nested_trim_id");
  const colorEl = document.querySelector(".nested_color_id");

  if (!makeEl && !modelEl && !trimEl && !colorEl) {
    return;
  }

  const BASE_URL = "searches";

  const init = () => {
    if (makeEl.value === "") {
      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);
    }

    if (modelEl.value === "") {
      clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      disableSelect(trimEl);
    }

    if ([...trimEl.options].filter(el => el.value !== "").length > 0) {
      enableSelect(trimEl);
    } else {
      disableSelect(trimEl);
      clearSelectElTarget(trimEl, "Não disponível");
    }

    if ([...colorEl.options].filter(el => el.value !== "").length > 0) {
      enableSelect(colorEl);
    } else {
      disableSelect(colorEl);
      clearSelectElTarget(colorEl, "Não disponível");
    }

    makeHandler();
  };

  const makeHandler = () => {
    makeEl.addEventListener("change", function() {
      let make_id = this.value;
      console.log(make_id);

      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);

      clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      disableSelect(trimEl);

      clearSelectElTarget(colorEl, "Selecione primeiro o Modelo");
      disableSelect(colorEl);

      if (make_id) {
        axios
          .get(`${BASE_URL}/models?q[make_id_eq]=${make_id}`)
          .then(function(response) {
            // Limpando OPTIONS
            clearEl(modelEl);

            // Populando OPTIONS
            if (response.data.models.length > 0) {
              enableSelect(modelEl);

              modelEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione o Modelo</option>`
              );

              response.data.models.map(current_data => {
                modelEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(modelEl, "Não disponível");
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      }
    });
    modelHandler();
  };

  const modelHandler = () => {
    modelEl.addEventListener("change", function() {
      let model_id = this.value;

      clearSelectElTarget(colorEl, "Selecione primeiro a Versão");
      disableSelect(colorEl);

      if (model_id) {
        axios
          .get(`${BASE_URL}/trims?q[model_id_eq]=${model_id}`)
          .then(function(response) {
            // Limpando OPTIONS
            clearEl(trimEl);

            // Populando OPTIONS
            if (response.data.trims.length > 0) {
              enableSelect(trimEl);

              trimEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Versão</option>`
              );

              response.data.trims.map(current_data => {
                trimEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
      }
    });

    trimHandler();
  };

  const trimHandler = () => {
    trimEl.addEventListener("change", function() {
      let trim_id = this.value;

      if (trim_id) {
        axios
          // .get(`${BASE_URL}/trim_colors?id=${trim_id}`)
          .get(`${BASE_URL}/colors`)
          .then(function(response) {
            // Limpando OPTIONS
            clearEl(colorEl);

            // Populando OPTIONS
            if (response.data.colors.length > 0) {
              enableSelect(colorEl);

              colorEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Cor</option>`
              );

              response.data.colors.map(current_data => {
                colorEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(colorEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(colorEl, "Selecione primeiro a Versão");
      }
    });
  };

  init();
};

$(document).ready(function() {
  $(".nestedData").on("cocoon:after-insert", function(e, insertedItem) {
    const make_id = "make_id_" + e.timeStamp;
    const model_id = "model_id_" + e.timeStamp;
    const trim_id = "trim_id_" + e.timeStamp;
    const color_id = "color_id_" + e.timeStamp;

    const makeEl = insertedItem[0].querySelector(".nested_make_id");
    const modelEl = insertedItem[0].querySelector(".nested_model_id");
    const trimEl = insertedItem[0].querySelector(".nested_trim_id");
    const colorEl = insertedItem[0].querySelector(".nested_color_id");

    
    makeEl.setAttribute("id", make_id);
    modelEl.setAttribute("id", model_id);
    trimEl.setAttribute("id", trim_id);
    colorEl.setAttribute("id", color_id);
    console.log(makeEl);

    if (!makeEl && !modelEl && !trimEl && !colorEl) {
      return;
    }

    const BASE_URL = "searches";

    if($("#make_id_hidden_input") && $("#make_id_hidden_input").val()){
      console.log(makeEl)
      $(makeEl).val($("#make_id_hidden_input").val())
      if ($("#make_id_hidden_input").val()) {
        axios
          .get(`${BASE_URL}/models?q[make_id_eq]=${$("#make_id_hidden_input").val()}`)
          .then(function(response) {
            // Limpando OPTIONS
            clearEl(modelEl);

            // Populando OPTIONS
            if (response.data.models.length > 0) {
              enableSelect(modelEl);

              modelEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione o Modelo</option>`
              );

              response.data.models.map(current_data => {
                modelEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(modelEl, "Não disponível");
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      }
    }


    const init = () => {
      if (makeEl.value === "") {
        clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
        disableSelect(modelEl);
      }

      if (modelEl.value === "") {
        clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
        disableSelect(trimEl);
      }

      if ([...trimEl.options].filter(el => el.value !== "").length > 0) {
        enableSelect(trimEl);
      } else {
        disableSelect(trimEl);
        clearSelectElTarget(trimEl, "Não disponível");
      }

      if ([...colorEl.options].filter(el => el.value !== "").length > 0) {
        enableSelect(colorEl);
      } else {
        disableSelect(colorEl);
        clearSelectElTarget(colorEl, "Não disponível");
      }

      makeHandler();
    };

    const makeHandler = () => {
      makeEl.addEventListener("change", function() {
        let make_id = this.value;
        
        clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
        disableSelect(modelEl);

        clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
        disableSelect(trimEl);

        clearSelectElTarget(colorEl, "Selecione primeiro o Modelo");
        disableSelect(colorEl);

        if (make_id) {
          axios
            .get(`${BASE_URL}/models?q[make_id_eq]=${make_id}`)
            .then(function(response) {
              // Limpando OPTIONS
              clearEl(modelEl);

              // Populando OPTIONS
              if (response.data.models.length > 0) {
                enableSelect(modelEl);

                modelEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="">Selecione o Modelo</option>`
                );

                response.data.models.map(current_data => {
                  modelEl.insertAdjacentHTML(
                    "beforeend",
                    `<option value="${current_data.id}">${current_data.name}</option>`
                  );
                });
              } else {
                clearSelectElTarget(modelEl, "Não disponível");
                clearSelectElTarget(trimEl, "Não disponível");
              }
            });
        } else {
          clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
        }
      });
      modelHandler();
    };

    const modelHandler = () => {
      modelEl.addEventListener("change", function() {
        let model_id = this.value;

        clearSelectElTarget(colorEl, "Selecione primeiro a Versão");
        disableSelect(colorEl);

        if (model_id) {
          axios
            .get(`${BASE_URL}/trims?q[model_id_eq]=${model_id}`)
            .then(function(response) {
              // Limpando OPTIONS
              clearEl(trimEl);

              // Populando OPTIONS
              if (response.data.trims.length > 0) {
                enableSelect(trimEl);

                trimEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="">Selecione a Versão</option>`
                );

                response.data.trims.map(current_data => {
                  trimEl.insertAdjacentHTML(
                    "beforeend",
                    `<option value="${current_data.id}">${current_data.name}</option>`
                  );
                });
              } else {
                clearSelectElTarget(trimEl, "Não disponível");
              }
            });
        } else {
          clearSelectElTarget(trimEl, "Selecione primeiro o Modelo");
        }
      });

      trimHandler();
    };

    const trimHandler = () => {
      trimEl.addEventListener("change", function() {
        let trim_id = this.value;

        if (trim_id) {
          axios
            // .get(`${BASE_URL}/trim_colors?id=${trim_id}`)
            .get(`${BASE_URL}/colors`)
            .then(function(response) {
              // Limpando OPTIONS
              clearEl(colorEl);

              // Populando OPTIONS
              if (response.data.colors.length > 0) {
                enableSelect(colorEl);

                colorEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="">Selecione a Cor</option>`
                );

                response.data.colors.map(current_data => {
                  colorEl.insertAdjacentHTML(
                    "beforeend",
                    `<option value="${current_data.id}">${current_data.name}</option>`
                  );
                });
              } else {
                clearSelectElTarget(colorEl, "Não disponível");
              }
            });
        } else {
          clearSelectElTarget(colorEl, "Selecione primeiro a Versão");
        }
      });
    };
    init();
  });
});



export default domReady(main);
