import dayjs from "dayjs";
import axios from "axios";

export const domReady = (fn) =>
  document.readyState === "interactive" || document.readyState === "complete"
    ? fn()
    : document.addEventListener("DOMContentLoaded", fn);

export const formatEvaluation = (
  evaluationForm,
  clientForm,
  vehicleForm,
  expenseForm,
  evaluationable_type,
  evaluationable_id
) => {
  let phone = {
    ddd: clientForm.phone
      .split(" ")[0]
      .split("")
      .map((c) => Number(c))
      .filter((c) => !isNaN(c))
      .join(""),
    number: clientForm.phone
      .split(" ")[1]
      .split("")
      .map((c) => Number(c))
      .filter((c) => !isNaN(c))
      .join(""),
  };
  let address = {
    street: clientForm.street,
    number: clientForm.number,
    zip_code: clientForm.cep,
    neighborhood: clientForm.neighborhood,
    state_id: clientForm.state_id,
    city_id: clientForm.city_id,
  };

  let note = {
    content: evaluationForm.note,
  };

  if (clientForm.address_id && clientForm.id) {
    address["id"] = clientForm.address_id;
  }

  if (clientForm.phone_id && clientForm.id) {
    phone["id"] = clientForm.phone_id;
  }

  if (evaluationForm.note_id && clientForm.id) {
    note["id"] = evaluationForm.note_id;
  }

  let clientData = {
    kind: clientForm.client_kind,
    cpf: clientForm.cpf,
    name: clientForm.name,
    cnpj: clientForm.cnpj,
    corporate_name: clientForm.corporate_name,
    assumed_name: clientForm.assumed_name,
    email: clientForm.email,
    phones_attributes: [phone],
    address_attributes: address,
  };

  if (clientForm.id) {
    clientData["id"] = clientForm.id;
  }

  let vehicleData = {
    license_plate: vehicleForm.license_plate,
    chassi: vehicleForm.chassi,
    renavam: vehicleForm.renavam,
    type: vehicleForm.type,
    manufacture_year: vehicleForm.manufacture_year,
    model_year: vehicleForm.model_year,
    make_id: vehicleForm.make_id,
    model_id: vehicleForm.model_id,
    trim_id: vehicleForm.trim_id,
    fuel_id: vehicleForm.fuel_id,
    color_id: vehicleForm.color_id,
    doors: vehicleForm.doors,
    engine: vehicleForm.engine,
    optional_ids: vehicleForm.optionals_vehicle_ids,
    city_id: vehicleForm.city_id,
    lead_id: vehicleForm.lead_id,
  };

  if (vehicleForm.id && clientForm.id) {
    vehicleData["id"] = vehicleForm.id;
  }

  let expenseData = expenseForm.map((item) => {
    if (item.id && clientForm.id) {
      return {
        id: item.id,
        description: item.description,
        amount: item.amount,
        value: item.value,
      };
    } else {
      return {
        description: item.description,
        amount: item.amount,
        value: item.value,
      };
    }
  });

  let evaluation = {
    kind: evaluationForm.kind,
    origin_id: evaluationForm.origin_id,
    dealer_id: evaluationForm.dealer_id,
    seller_id: evaluationForm.seller_id,
    mileage: vehicleForm.mileage,
    engine_score: evaluationForm.engine_score,
    gearbox_score: evaluationForm.gearbox_score,
    is_auction: evaluationForm.is_auction,
    final_judgment: evaluationForm.final_judgment,
    notes_attributes: [note],
    client_attributes: clientData,
    vehicle_attributes: vehicleData,
    expenses_attributes: expenseData,
    evaluationable_type,
    evaluationable_id,
  };

  if (evaluationForm.destination) {
    evaluation = { ...evaluation, destination: evaluationForm.destination };
  }

  if (evaluationForm.trading_value) {
    evaluation = { ...evaluation, trading_value: evaluationForm.trading_value };
  }

  if (evaluationForm.value) {
    evaluation = { ...evaluation, value: evaluationForm.value };
  }

  if (evaluationForm.value_addition) {
    evaluation = {
      ...evaluation,
      value_addition: evaluationForm.value_addition,
    };
  }

  if (evaluationForm.value_bonus) {
    evaluation = { ...evaluation, value_bonus: evaluationForm.value_bonus };
  }

  if (clientForm.id) {
    evaluation["client_id"] = clientForm.id;
  }

  return evaluation;
};

export default document.addEventListener("DOMContentLoaded", function () {
  $("#client_attendance").on("change", function () {
    if (this.value) {
      $.get(
        "/api/v1/searches/clients?q[id_eq]=" + this.value,
        {},
        function (data) {
          if (data.clients && data.clients[0]) {
            $("#attendance_client_id").attr("value", data.clients[0].id);
            $("#attendance_name").attr("value", data.clients[0].name);
            $("#attendance_email").attr("value", data.clients[0].email);
            $("#attendance_name").attr("value", data.clients[0].name);
            $("#attendance_phone_number").attr(
              "value",
              data.clients[0].phone_number
            );
            $("#attendance_client_attributes_cpf").attr(
              "value",
              data.clients[0].cpf
            );
            $("#attendance_client_attributes_name").attr(
              "value",
              data.clients[0].name
            );
            $("#attendance_client_attributes_email").attr(
              "value",
              data.clients[0].email
            );
            $(".phoneFull").attr("value", data.clients[0].phone_number_apollo);

            // $('#attendance_name').attr("disabled", true)
            // $('#attendance_email').attr("disabled", true)
            // $('#attendance_phone_number').attr("disabled", true)
          }
        }
      );
    } else {
      $("#attendance_client_id").val("");
      $("#attendance_name").val("");
      $("#attendance_email").val("");
      $("#attendance_phone_number").val("");
      $("#attendance_client_id").attr("disabled", false);
      $("#attendance_name").attr("disabled", false);
      $("#attendance_email").attr("disabled", false);
      $("#attendance_phone_number").attr("disabled", false);
    }
  });

  $("#reservation_attendance_id").on("change", function () {
    if (this.value) {
      $.get(
        "/api/v1/searches/attendances?q[id_eq]=" + this.value,
        {},
        function (data) {
          if (data.attendances && data.attendances[0]) {
            $("#attendance_id").attr("value", data.attendances[0].id);
          }
        }
      );
    } else {
      $("#attendance_id").val("");
      $("#attendance_id").attr("disabled", false);
    }
  });
});
