import Vue from "vue";
import store from "./store";
import "./components/reports";
import "./components/sidebar";
import "./components/leads";
import "./components/stock";
import "./components/answers";
import "./components/attendances";
import "./components/workshops";
import "./components/statcard";
import "./components/targets";
import "./components/campaigns";
import "./components/evaluations";
import "./components/gallery";
import "./components/prospections";
import "./plugins";

new Vue({
  el: "#app",
  store,
});
