import { domReady } from "../../utils";
import axios from "axios";
import {
  enableSelect,
  disableSelect,
  clearSelectElTarget,
  clearEl
} from "./concerns";

const main = () => {
  const makeEl = document.querySelector("#mMake_id");
  const modelEl = document.querySelector("#mModel_id");

  if (!makeEl && !modelEl) {
    return;
  }

  const BASE_URL = "searches";

  const init = () => {
    if (makeEl.value === "") {
      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);
    }

    makeHandler();
  };

  const makeHandler = () => {
    makeEl.addEventListener("change", function() {
      let make_id = this.value;

      clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      disableSelect(modelEl);

      if (make_id) {
        axios
          .get(
            // `${BASE_URL}/models?q[type_eq]=New::Model&q[make_id_eq]=${make_id}`
            `${BASE_URL}/models?q[make_id_eq]=${make_id}`
          )
          .then(function(response) {
            // Limpando OPTIONS
            clearEl(modelEl);

            // Populando OPTIONS
            if (response.data.models.length > 0) {
              enableSelect(modelEl);

              modelEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione o Modelo</option>`
              );

              response.data.models.map(current_data => {
                modelEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(modelEl, "Não disponível");
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(modelEl, "Selecione primeiro a Marca");
      }
    });
  };

  init();
};

export default domReady(main);
