$(document).ready(function () {
  const searchPlateTarget = document.querySelector("#search_plate_target");
  const fieldTargetList = Array.from(
    document.querySelectorAll(".plate_search_control")
  );

  if (searchPlateTarget) {
    checkStateFields(searchPlateTarget);

    searchPlateTarget.addEventListener("input", function (e) {
      checkStateFields(e.target);
    });
  }

  function checkStateFields(element) {
    if (element.value.length > 0) {
      fieldTargetList.map((el) => {
        el.setAttribute("disabled", "disabled");
      });
    } else {
      fieldTargetList.map((el) => {
        el.removeAttribute("disabled");
      });
    }
  }
});
