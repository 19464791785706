$(document).ready(function () {
  if ($("#integration_client_kind_landing_page").attr("checked")) {
    selectPages();
  } else if ($("#integration_client_kind_olx").attr("checked")) {
    selectOlx();
  } else {
    selectCarhoo();
  }

  $("#integration_client_kind_landing_page").on("click", () => {
    selectPages();
  });
  $("#integration_client_kind_olx").on("click", () => {
    selectOlx();
  });
  $("#integration_client_kind_carhoo").on("click", () => {
    selectCarhoo();
  });
});

const selectPages = () => {
  $(".carhoo_olx_fields").addClass("d-none");
  $(".olx_fields").addClass("d-none");
  $(".carhoo_fields").addClass("d-none");
};

const selectOlx = () => {
  $(".carhoo_olx_fields").removeClass("d-none");
  $(".olx_fields").removeClass("d-none");

  $(".carhoo_fields").addClass("d-none");
};

const selectCarhoo = () => {
  $(".carhoo_olx_fields").removeClass("d-none");
  $(".carhoo_fields").removeClass("d-none");

  $(".olx_fields").addClass("d-none");
};
