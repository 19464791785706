import Vue from "vue";
import Photoswipe from "vue-pswipe";

const options = {
  index: 3,
  escKey: true,
  // ui option
  timeToIdle: 4000
};

Vue.use(Photoswipe, options);
