export default {
  state: () => ({
    id: null,
    client_kind: null,
    cpf: null,
    name: null,
    cnpj: null,
    corporate_name: null,
    assumed_name: null,
    email: null,
    street: null,
    number: null,
    cep: null,
    neighborhood: null,
    state_id: null,
    city_id: null,
    phone: null,
    address_id: null,
    phone_id: null,
    cities: []
  }),
  mutations: {
    setClientId(state, payload) {
      state.id = payload;
    },
    setCities(state, payload) {
      state.cities = payload;
    },
    setClientKind(state, payload) {
      state.client_kind = payload;
    },
    setCpf(state, payload) {
      state.cpf = payload;
    },
    setName(state, payload) {
      state.name = payload;
    },
    setCnpj(state, payload) {
      state.cnpj = payload;
    },
    setCorporateName(state, payload) {
      state.corporate_name = payload;
    },
    setAssumedName(state, payload) {
      state.assumed_name = payload;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setStreet(state, payload) {
      state.street = payload;
    },
    setStateId(state, payload) {
      state.state_id = payload;
    },
    setCityId(state, payload) {
      state.city_id = payload;
    },
    setCep(state, payload) {
      state.cep = payload;
    },
    setNeighborhood(state, payload) {
      state.neighborhood = payload;
    },
    setNumber(state, payload) {
      state.number = payload;
    },
    setPhone(state, payload) {
      state.phone = payload;
    },
    setPhoneId(state, payload) {
      state.phone_id = payload;
    },
    setAddressId(state, payload) {
      state.address_id = payload;
    },
    setClientForm(state, payload) {
      state.id = payload.id;
      state.client_kind = payload.client_kind;
      state.cpf = payload.cpf;
      state.name = payload.name;
      state.cnpj = payload.cnpj;
      state.corporate_name = payload.corporate_name;
      state.assumed_name = payload.assumed_name;
      state.email = payload.email;
      state.phone = payload.phone;
      state.phone_id = payload.phone_id;
      state.address_id = payload.address_id;
      state.street = payload.street;
      state.number = payload.number;
      state.cep = payload.cep;
      state.neighborhood = payload.neighborhood;
      state.state_id = payload.state_id;
      state.city_id = payload.city_id;
    },
  },
  actions: {},
};
