import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const dataToken = document.querySelector("#token");

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = "/api/v1/";

if (dataToken) {
  Vue.axios.defaults.headers.common["Authorization"] = dataToken.dataset.token;
}

// Vue.use({
//   install(Vue) {
//     Vue.prototype.$http = axios.create({
//       baseURL: 'http://localhost:3001/api/v1/'
//     })
//   }
// })
