import axios from "axios";
const BASE_URL = "searches";

$(document).ready(function () {
  if ($("#kmmtc_kind_status").val() == "consortium") {
    $("#consortium_plans").show();
  } else {
    $("#consortium_plans").hide();
  }

  if ($("#kmmtc_kind").val() == "1") {
    // console.log("remove consorcio");
    $("select#kmmtc_kind_status option[value='consortium']").remove();
  } else {
    if (
      $("select#kmmtc_kind_status option[value='consortium']").val() &&
      $("select#kmmtc_kind_status option[value='consortium']").val() !=
        "consortium"
    ) {
      console.log("add consorcio");
      $("select#kmmtc_kind_status").append(
        new Option("Consórcio", "consortium")
      );
    }
  }

  // SELECIONA O TIPO COMO MOTO PARA USUÁRIOS DA JAPAN
  if ($("#dealer_id_attendance").data("moto-user")) {
    $("#kmmtc_kind").val("2").change();
    $("#kmmtc_kind_status").attr("disabled", false);
  }

  $("#kmmtc_kind_status").on("change", function () {
    // console.log($(this).val());

    if ($(this).val() == "newed") {
      console.log($("#kmmtc_kind_status").attr("data-make"));
      if ($("#kmmtc_kind_status").attr("data-make")) {
        $("#kmmtc_make_id").val($("#kmmtc_kind_status").attr("data-make"));
        $("#kmmtc_make_id").attr("disabled", true);
        $.ajax({
          url: `/api/v1/searches/models?q[make_id_eq]=${$(
            "#kmmtc_kind_status"
          ).attr("data-make")}&q[kind_eq]=1&only_dealer=true`,
          type: "GET",
        }).done(function (data) {
          $("#kmmtc_model_id").attr("disabled", false);
          $("#kmmtc_model_id").html("");
          $("#kmmtc_model_id").append(
            "<option value'>Selecione o Modelo</option>"
          );
          data.models.forEach(function (element, index, array) {
            $("#kmmtc_model_id").append(
              "<option value='" +
                element["id"] +
                "'>" +
                element["name"] +
                "</option>"
            );
          });
        });
      }

      $("#consortium_plans").hide();
    } else {
      $("#kmmtc_make_id").attr("disabled", false);

      if ($("#kmmtc_kind_status").val() == "consortium") {
        $("#consortium_plans").show();
      } else {
        $("#consortium_plans").hide();
      }

      if ($("#kmmtc_kind").val() == "1") {
        $("select#kmmtc_kind_status option[value='consortium']").remove();
      }
    }

    if (
      $("#dealer_id_attendance").data("moto-user") &&
      ($(this).val() == "newed" || $(this).val() == "consortium")
    ) {
      // CARREGA MARCAS
      $("#kmmtc_make_id").attr("disabled", true);
      $.ajax({
        url: `/api/v1/searches/makes?q[kind_eq]=2&only_dealer=true`,
        type: "GET",
      }).done(function (data) {
        $("#kmmtc_make_id").html("");
        $("#kmmtc_make_id").append("<option value'>Selecione a Marca</option>");
        data.makes.forEach(function (element, index, array) {
          $("#kmmtc_make_id").append(
            "<option value='" +
              element["id"] +
              "'>" +
              element["name"] +
              "</option>"
          );
        });

        // SETA A HONDA COMO MARCA SELECIONADA: ID 80
        $("#kmmtc_make_id").val("80").change();

        // CARREGA MODELOS DA HONDA: ID 80
        $.ajax({
          url: `/api/v1/searches/models?q[make_id_eq]=80`,
          type: "GET",
        }).done(function (data) {
          console.log("carrega modelos ");
          $("#kmmtc_model_id").attr("disabled", false);
          $("#kmmtc_model_id").html("");
          $("#kmmtc_model_id").append(
            "<option value'>Selecione o Modelo</option>"
          );
          data.models.forEach(function (element, index, array) {
            $("#kmmtc_model_id").append(
              "<option value='" +
                element["id"] +
                "'>" +
                element["name"] +
                "</option>"
            );
          });
          console.log(data);
        });
      });
    }
  });

  $("#dealer_id_attendance").on("change", function () {
    const userEl = document.querySelector("#seller_id_attendance");
    userEl.innerHTML = "";
    const dealer_id = $("#dealer_id_attendance").val();
    axios
      .get(`${BASE_URL}/sellers?q[dealer_id_eq]=${dealer_id}`)
      .then(function (response) {
        if (response.data.sellers.length > 0) {
          userEl.insertAdjacentHTML(
            "beforeend",
            `<option value="">Selecione o Vendedor</option>`
          );

          response.data.sellers.map((current_data) => {
            userEl.insertAdjacentHTML(
              "beforeend",
              `<option value="${current_data.id}">${current_data.name} (${current_data.dealer})</option>`
            );
          });
        }
      });
  });

  $("[data-js-evaluation-id]")
    .on("ajax:success", function (event, response, status, xhr) {
      Swal.fire({
        icon: "success",
        title: "Enviado com sucesso!",
        showConfirmButton: false,
        timer: 1500,
      });
      var evaluation_id = parseInt(this.dataset.jsEvaluationId);
      $("[data-js-evaluation-id=" + evaluation_id + "]").hide();
    })
    .on("ajax:error", function (event, response, xhr, status) {
      alert("Erro no envio!");
    });

  $(".search_cpf_apollo").on("focusout", function () {
    const cpf = `q[cpf_eq]=${$(this).val()}`;
    const dealer_id = $(this).attr("data-dealer");

    console.log($(".search_cpf_apollo").val().length);
    if ($(".search_cpf_apollo").val().length >= 13) {
      console.log($(".search_cpf_apollo").val().length);
      $.ajax({
        url: `/api/v1/searches/clients?${cpf}&dealer_id=${dealer_id}`,
        type: "GET",
      }).done(function (data) {
        if (data.clients.length > 0) {
          const client = data.clients[0];
          const phone = client.phones ? client.phones[0] : null;
          const address = client.address;

          console.log(client);
          $(".client_id").val(client.id);
          $(".name_search").val(client.name);
          $(".email_search").val(client.email);
          if (phone) {
            $(".ddd_search").val(phone.ddd);
            $(".number_phone_search").val(phone.number);
          }

          if (address) {
            $(".cep_search").val("");
            $(".street_search").val(address.street);
            $(".number_search").val(address.number);
            $(".neighborhood_search").val(address.neighborhood);
            $("#state_id").val(address.state_id);

            get_cities(address.state_id, address.city_id);
          }
        } else {
          $(".name_search").val("");
          $(".email_search").val("");
          $(".ddd_search").val("");
          $(".number_search").val("");

          $(".cep_search").val("");
          $(".street_search").val("");
          $(".number_search").val("");
          $(".neighborhood_search").val("");
          $("#state_id").val("");
          $("#city_id").val("");
        }
      });
    }
  });

  $(".search_cnpj_apollo").on("focusout", function () {
    const cpf = `q[cnpj_eq]=${$(this).val()}`;
    const dealer_id = $(this).attr("data-dealer");

    console.log($(".search_cnpj_apollo").val().length);
    if ($(".search_cnpj_apollo").val().length >= 13) {
      console.log($(".search_cnpj_apollo").val().length);
      $.ajax({
        url: `/api/v1/searches/clients?${cpf}&dealer_id=${dealer_id}`,
        type: "GET",
      }).done(function (data) {
        if (data.clients.length > 0) {
          const client = data.clients[0];
          const phone = client.phones ? client.phones[0] : null;
          const address = client.address;

          console.log(client);
          $(".client_id").val(client.id);
          $(".assumed_name_search").val(client.assumed_name);
          $(".corporate_name_search").val(client.corporate_name);
          $(".email_search").val(client.email);
          if (phone) {
            $(".ddd_search").val(phone.ddd);
            $(".number_phone_search").val(phone.number);
          }

          if (address) {
            $(".cep_search").val("");
            $(".street_search").val(address.street);
            $(".number_search").val(address.number);
            $(".neighborhood_search").val(address.neighborhood);
            $("#state_id").val(address.state_id);

            get_cities(address.state_id, address.city_id);
          }
        } else {
          $(".name_search").val("");
          $(".assumed_name_search").val("");
          $(".corporate_name_search").val("");
          $(".email_search").val("");
          $(".ddd_search").val("");
          $(".number_search").val("");

          $(".cep_search").val("");
          $(".street_search").val("");
          $(".number_search").val("");
          $(".neighborhood_search").val("");
          $("#state_id").val("");
          $("#city_id").val("");
        }
      });
    }
  });

  $("#make").on("change", function () {
    let id_value = $(this).val().toLowerCase();
    // let value = $("#make option:selected").text().toLowerCase();
    clear_select("#model", "Selecione o Modelo");
    clear_select("#trim", "Selecione a Versão");
    clear_select("#fipe", "Selecione a Fipe");
    // if(value == 'marca'){
    //   value = ''
    // }
    if (id_value) {
      console.log(`# 2 - AQUI! id_value = ${id_value}`);
      $.ajax({
        // url: "/modelos/makes/" + id_value,
        url: `/api/v1/searches/models?q[make_id_eq]=${id_value}&q[kind_eq]=${
          $("#kmmtc_kind_status").val() == "used" ? 0 : 1
        }&only_dealer=true`,
        type: "GET",
      }).done(function (data) {
        $("#model").html("");
        $("#model").append("<option value'>Selecione o Modelo</option>");
        data.forEach(function (element, index, array) {
          $("#model").append(
            "<option value='" +
              element["id"] +
              "'>" +
              element["name"] +
              "</option>"
          );
        });
      });
    }
  });

  $("#model").on("change", function () {
    let id_value = $(this).val().toLowerCase();
    clear_select("#trim", "Selecione a Versão");
    clear_select("#fipe", "Selecione a Fipe");
    if (id_value) {
      $.ajax({
        url: "/versoes/models/" + id_value,
        type: "GET",
      }).done(function (data) {
        $("#trim").html("");
        $("#trim").append("<option value'>Selecione a Versão</option>");
        data.forEach(function (element, index, array) {
          $("#trim").append(
            "<option value='" +
              element["id"] +
              "'>" +
              element["name"] +
              "</option>"
          );
        });
      });
    }
  });

  $("#trim").on("change", function () {
    let id_value = $(this).val().toLowerCase();
    clear_select("#fipe", "Selecione a Fipe");
    if (id_value) {
      $.ajax({
        url: "/versoes/fipe/" + id_value,
        type: "GET",
      }).done(function (data) {
        $("#fipe").html("");
        $("#fipe").append("<option value'>Selecione a Fipe</option>");
        data.forEach(function (element, index, array) {
          $("#fipe").append(
            "<option value='" +
              element["id"] +
              "'>" +
              element["name"] +
              "</option>"
          );
        });
      });
    }
  });

  const clear_select = (id, name) => {
    $(id).html("");
    $(id).append("<option value=''>" + name + "</option>");
  };

  const get_cities = (state_id, city_id) => {
    $.ajax({
      url: `/api/v1/searches/cities?q[state_id_eq]=${state_id}`,
      type: "GET",
    }).done(function (data) {
      $("#city_id").html("");
      $("#city_id").attr("disabled", false);
      $("#city_id").append("<option value'>Selecione a Cidade</option>");

      data.cities.forEach(function (element, index, array) {
        $("#city_id").append(
          "<option value='" +
            element["id"] +
            "'>" +
            element["name"] +
            "</option>"
        );
      });

      $("#city_id").val(city_id);
    });
  };
});
