import { domReady } from "../utils";

const selectKindClientForForm = () => {
  let selected = document.querySelector("input[name='client[kind]']:checked");
  let selected_client = document.querySelector(
    "input[name='attendance[client_attributes][kind]']:checked"
  );

  hideAllFields();

  if (selected) {
    showHideFields(selected.value);
  }

  if (selected_client) {
    showHideFields(selected_client.value);
  }

  $(".clientKind").on("change", "input[name='client[kind]']", function () {
    let current_selected = document.querySelector(
      "input[name='client[kind]']:checked"
    ).value;

    showHideFields(current_selected);
  });

  $(".clientKind").on(
    "change",
    "input[name='attendance[client_attributes][kind]']",
    function () {
      console.log("CHANGE");
      let current_selected = document.querySelector(
        "input[name='attendance[client_attributes][kind]']:checked"
      ).value;

      showHideFields(current_selected);
    }
  );
};

const showHideFields = (selected_value) => {
  switch (selected_value) {
    case "individual":
      $(".corporate").hide();
      $(".individual").show();
      break;
    case "corporate":
      $(".corporate").show();
      $(".individual").hide();
      break;
    default:
      $(".corporate").hide();
      $(".individual").hide();
  }
};

const hideAllFields = () => {
  $(".corporate").hide();
  $(".individual").hide();
};

export default domReady(selectKindClientForForm);
