!(function(e, t) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = t(require("jquery")))
    : "function" == typeof define && define.amd
    ? define(["jquery"], t)
    : "object" == typeof exports
    ? (exports.autonumeric = t(require("jquery")))
    : (e.autonumeric = t(e.jQuery));
})(this, function(e) {
  return (function(e) {
    function t(a) {
      if (i[a]) return i[a].exports;
      var n = (i[a] = { exports: {}, id: a, loaded: !1 });
      return e[a].call(n.exports, n, n.exports, t), (n.loaded = !0), n.exports;
    }
    var i = {};
    return (t.m = e), (t.c = i), (t.p = ""), t(0);
  })([
    function(e, t, i) {
      var a, n, r;
      i(1), i(1);
      (function() {
        "use strict";
        Object.defineProperty(t, "__esModule", { value: !0 });
        var o = (function() {
            function e(e, t) {
              var i = [],
                a = !0,
                n = !1,
                r = void 0;
              try {
                for (
                  var o, s = e[Symbol.iterator]();
                  !(a = (o = s.next()).done) &&
                  (i.push(o.value), !t || i.length !== t);
                  a = !0
                );
              } catch (e) {
                (n = !0), (r = e);
              } finally {
                try {
                  !a && s.return && s.return();
                } finally {
                  if (n) throw r;
                }
              }
              return i;
            }
            return function(t, i) {
              if (Array.isArray(t)) return t;
              if (Symbol.iterator in Object(t)) return e(t, i);
              throw new TypeError(
                "Invalid attempt to destructure non-iterable instance"
              );
            };
          })(),
          s =
            "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
              ? function(e) {
                  return typeof e;
                }
              : function(e) {
                  return e &&
                    "function" == typeof Symbol &&
                    e.constructor === Symbol &&
                    e !== Symbol.prototype
                    ? "symbol"
                    : typeof e;
                },
          u = void 0,
          l = void 0,
          c = void 0,
          h = void 0,
          g = void 0,
          p = [
            "b",
            "caption",
            "cite",
            "code",
            "const",
            "dd",
            "del",
            "div",
            "dfn",
            "dt",
            "em",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "ins",
            "kdb",
            "label",
            "li",
            "option",
            "output",
            "p",
            "q",
            "s",
            "sample",
            "span",
            "strong",
            "td",
            "th",
            "u"
          ],
          f = {
            aSep: ",",
            nSep: !1,
            dGroup: "3",
            aDec: ".",
            altDec: null,
            aSign: "",
            pSign: "p",
            pNeg: "l",
            aSuffix: "",
            oLimits: null,
            vMax: "9999999999999.99",
            vMin: "-9999999999999.99",
            mDec: null,
            eDec: null,
            scaleDivisor: null,
            scaleDecimal: null,
            scaleSymbol: null,
            aStor: !1,
            mRound: "S",
            aPad: !0,
            nBracket: null,
            wEmpty: "focus",
            lZero: "allow",
            aForm: !0,
            sNumber: !1,
            anDefault: null,
            unSetOnSubmit: !1,
            outputType: null,
            debug: !1
          },
          d = {
            Backspace: 8,
            Tab: 9,
            Enter: 13,
            Shift: 16,
            Ctrl: 17,
            Alt: 18,
            PauseBreak: 19,
            CapsLock: 20,
            Esc: 27,
            Space: 32,
            PageUp: 33,
            PageDown: 34,
            End: 35,
            Home: 36,
            LeftArrow: 37,
            UpArrow: 38,
            RightArrow: 39,
            DownArrow: 40,
            Insert: 45,
            Delete: 46,
            num0: 48,
            num1: 49,
            num2: 50,
            num3: 51,
            num4: 52,
            num5: 53,
            num6: 54,
            num7: 55,
            num8: 56,
            num9: 57,
            a: 65,
            b: 66,
            c: 67,
            d: 68,
            e: 69,
            f: 70,
            g: 71,
            h: 72,
            i: 73,
            j: 74,
            k: 75,
            l: 76,
            m: 77,
            n: 78,
            o: 79,
            p: 80,
            q: 81,
            r: 82,
            s: 83,
            t: 84,
            u: 85,
            v: 86,
            w: 87,
            x: 88,
            y: 89,
            z: 90,
            Windows: 91,
            RightClick: 93,
            numpad0: 96,
            numpad1: 97,
            numpad2: 98,
            numpad3: 99,
            numpad4: 100,
            numpad5: 101,
            numpad6: 102,
            numpad7: 103,
            numpad8: 104,
            numpad9: 105,
            MultiplyNumpad: 106,
            PlusNumpad: 107,
            MinusNumpad: 109,
            DotNumpad: 110,
            SlashNumpad: 111,
            F1: 112,
            F2: 113,
            F3: 114,
            F4: 115,
            F5: 116,
            F6: 117,
            F7: 118,
            F8: 119,
            F9: 120,
            F10: 121,
            F11: 122,
            F12: 123,
            NumLock: 144,
            ScrollLock: 145,
            MyComputer: 182,
            MyCalculator: 183,
            Semicolon: 186,
            Equal: 187,
            Comma: 188,
            Hyphen: 189,
            Dot: 190,
            Slash: 191,
            Backquote: 192,
            LeftBracket: 219,
            Backslash: 220,
            RightBracket: 221,
            Quote: 222,
            Command: 224
          };
        !(function(o) {
          (n = [i(1)]),
            (a = o),
            (r = "function" == typeof a ? a.apply(t, n) : a),
            !(void 0 !== r && (e.exports = r));
        })(function(e) {
          function t(e) {
            return null === e;
          }
          function i(e) {
            return void 0 === e;
          }
          function a(e) {
            return null === e || void 0 === e || "" === e;
          }
          function n(e) {
            return "string" == typeof e || e instanceof String;
          }
          function r(e) {
            return "boolean" == typeof e;
          }
          function v(e) {
            var t = String(e).toLowerCase();
            return "true" === t || "false" === t;
          }
          function m(e) {
            return (
              "object" === ("undefined" == typeof e ? "undefined" : s(e)) &&
              null !== e &&
              !Array.isArray(e)
            );
          }
          function S(e) {
            for (var t in e) if (e.hasOwnProperty(t)) return !1;
            return !0;
          }
          function N(e) {
            return "" !== e && !isNaN(e);
          }
          function b(e, t) {
            return B(e, t.settingsClone).replace(t.settingsClone.aDec, ".");
          }
          function y(e, t) {
            return (
              !(!n(e) || !n(t) || "" === e || "" === t) && e.indexOf(t) !== -1
            );
          }
          function D(e, t) {
            return !(!w(t) || t === [] || i(e)) && t.indexOf(e) !== -1;
          }
          function w(e) {
            if ("[object Array]" === Object.prototype.toString.call([]))
              return (
                Array.isArray(e) ||
                ("object" === ("undefined" == typeof e ? "undefined" : s(e)) &&
                  "[object Array]" === Object.prototype.toString.call(e))
              );
            throw new Error("toString message changed for Object Array");
          }
          function x(e) {
            var t = e.split("."),
              a = o(t, 2),
              n = a[1];
            return !i(n);
          }
          function k(e) {
            var t = e.split("."),
              a = o(t, 2),
              n = a[1];
            return i(n) ? null : n.length;
          }
          function C(e) {
            var t = {};
            if (i(e.selectionStart)) {
              e.focus();
              var a = document.selection.createRange();
              (t.length = a.text.length),
                a.moveStart("character", -e.value.length),
                (t.end = a.text.length),
                (t.start = t.end - t.length);
            } else (t.start = e.selectionStart), (t.end = e.selectionEnd), (t.length = t.end - t.start);
            return t;
          }
          function A(e, t, a) {
            if (i(e.selectionStart)) {
              e.focus();
              var n = e.createTextRange();
              n.collapse(!0),
                n.moveEnd("character", a),
                n.moveStart("character", t),
                n.select();
            } else (e.selectionStart = t), (e.selectionEnd = a);
          }
          function M(e) {
            throw new Error(e);
          }
          function O(e) {
            var t =
              arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
            t && console.warn("Warning: " + e);
          }
          function T(t, i) {
            e.each(i, function(e, a) {
              "function" == typeof a
                ? (i[e] = a(t, i, e))
                : "function" == typeof t.autoNumeric[a] &&
                  (i[e] = t.autoNumeric[a](t, i, e));
            });
          }
          function P(e, t) {
            var i = 0,
              a = 0;
            return (
              t[1] && (i = t[1].length),
              e[1] && (a = e[1].length),
              Math.max(i, a)
            );
          }
          function V(e, t) {
            T(e, t);
            var i = t.vMax.toString().split("."),
              a = t.vMin || 0 === t.vMin ? t.vMin.toString().split(".") : [];
            (i[0] = i[0].replace("-", "")),
              (a[0] = a[0].replace("-", "")),
              (t.mIntPos = Math.max(i[0].length, 1)),
              (t.mIntNeg = Math.max(a[0].length, 1)),
              null === t.mDec
                ? ((t.mDec = P(a, i)), (t.oDec = t.mDec))
                : (t.mDec = Number(t.mDec)),
              (t.mDec =
                t.scaleDivisor && t.scaleDecimal ? t.scaleDecimal : t.mDec),
              null === t.altDec &&
                t.mDec > 0 &&
                ("." === t.aDec && "," !== t.aSep
                  ? (t.altDec = ",")
                  : "," === t.aDec && "." !== t.aSep && (t.altDec = "."));
            var n = t.aNeg ? "([-\\" + t.aNeg + "]?)" : "(-?)";
            (t.aNegRegAutoStrip = n),
              (t.skipFirstAutoStrip = new RegExp(
                n +
                  "[^-" +
                  (t.aNeg ? "\\" + t.aNeg : "") +
                  "\\" +
                  t.aDec +
                  "\\d].*?(\\d|\\" +
                  t.aDec +
                  "\\d)"
              )),
              (t.skipLastAutoStrip = new RegExp(
                "(\\d\\" + t.aDec + "?)[^\\" + t.aDec + "\\d]\\D*$"
              ));
            var r = "-0123456789\\" + t.aDec;
            return (
              (t.allowedAutoStrip = new RegExp("[^" + r + "]", "gi")),
              (t.numRegAutoStrip = new RegExp(
                n +
                  "(?:\\" +
                  t.aDec +
                  "?(\\d+\\" +
                  t.aDec +
                  "\\d+)|(\\d*(?:\\" +
                  t.aDec +
                  "\\d*)?))"
              )),
              t
            );
          }
          function B(e, t) {
            if (("" !== t.aSign && (e = e.replace(t.aSign, "")), t.aSuffix))
              for (; y(e, t.aSuffix); ) e = e.replace(t.aSuffix, "");
            (e = e.replace(t.skipFirstAutoStrip, "$1$2")),
              ("s" === t.pNeg || ("s" === t.pSign && "p" !== t.pNeg)) &&
                y(e, "-") &&
                "" !== e &&
                (t.trailingNegative = !0),
              (e = e.replace(t.skipLastAutoStrip, "$1")),
              (e = e.replace(t.allowedAutoStrip, "")),
              t.altDec && (e = e.replace(t.altDec, t.aDec));
            var a = e.match(t.numRegAutoStrip);
            if (
              ((e = a ? [a[1], a[2], a[3]].join("") : ""),
              "allow" === t.lZero || "keep" === t.lZero)
            ) {
              var n = "",
                r = e.split(t.aDec),
                s = o(r, 2),
                u = s[0],
                l = s[1],
                c = u;
              y(c, t.aNeg) && ((n = t.aNeg), (c = c.replace(t.aNeg, ""))),
                "" === n &&
                  c.length > t.mIntPos &&
                  "0" === c.charAt(0) &&
                  (c = c.slice(1)),
                "" !== n &&
                  c.length > t.mIntNeg &&
                  "0" === c.charAt(0) &&
                  (c = c.slice(1)),
                (e = "" + n + c + (i(l) ? "" : t.aDec + l));
            }
            if (
              (t.onOff && "deny" === t.lZero) ||
              ("allow" === t.lZero && t.onOff === !1)
            ) {
              var h = "^" + t.aNegRegAutoStrip + "0*(\\d)";
              (h = new RegExp(h)), (e = e.replace(h, "$1$2"));
            }
            return e;
          }
          function F(e, t) {
            if (
              ("p" === t.pSign && "l" === t.pNeg) ||
              ("s" === t.pSign && "p" === t.pNeg)
            ) {
              var i = t.nBracket.split(","),
                a = o(i, 2),
                n = a[0],
                r = a[1];
              t.onOff
                ? t.onOff &&
                  e.charAt(0) === n &&
                  ((e = e.replace(n, t.aNeg)), (e = e.replace(r, "")))
                : ((e = e.replace(t.aNeg, "")), (e = n + e + r));
            }
            return e;
          }
          function E(e) {
            return (
              (e = e.replace(",", ".")),
              y(e, "-") &&
                e.lastIndexOf("-") === e.length - 1 &&
                ((e = e.replace("-", "")), (e = "-" + e)),
              e
            );
          }
          function R(e, i) {
            if (t(i) || "string" === i) return e;
            var a = void 0;
            switch (i) {
              case "number":
                a = Number(e);
                break;
              case ".-":
                a = y(e, "-") ? e.replace("-", "") + "-" : e;
                break;
              case ",":
              case "-,":
                a = e.replace(".", ",");
                break;
              case ",-":
                (a = e.replace(".", ",")),
                  (a = y(a, "-") ? a.replace("-", "") + "-" : a);
                break;
              case ".":
              case "-.":
                a = e;
                break;
              default:
                M("The given outputType [" + i + "] option is not recognized.");
            }
            return a;
          }
          function j(e, t) {
            return (
              "." !== t.aDec && (e = e.replace(t.aDec, ".")),
              "-" !== t.aNeg && "" !== t.aNeg && (e = e.replace(t.aNeg, "-")),
              e.match(/\d/) || (e += "0.00"),
              e
            );
          }
          function L(e, t) {
            return (
              "-" !== t.aNeg && "" !== t.aNeg && (e = e.replace("-", t.aNeg)),
              "." !== t.aDec && (e = e.replace(".", t.aDec)),
              e
            );
          }
          function $(e, t, i) {
            return "" === e || e === t.aNeg
              ? "always" === t.wEmpty || i
                ? "l" === t.pNeg
                  ? e + t.aSign + t.aSuffix
                  : t.aSign + e + t.aSuffix
                : e
              : null;
          }
          function I(e, t) {
            t.strip && (e = B(e, t)),
              t.trailingNegative && !y(e, "-") && (e = "-" + e);
            var a = $(e, t, !0),
              n = y(e, "-");
            if ((n && (e = e.replace("-", "")), null !== a)) return a;
            var r = "";
            (t.dGroup = t.dGroup.toString()),
              (r =
                "2" === t.dGroup
                  ? /(\d)((\d)(\d{2}?)+)$/
                  : "2s" === t.dGroup
                  ? /(\d)((?:\d{2}){0,2}\d{3}(?:(?:\d{2}){2}\d{3})*?)$/
                  : "4" === t.dGroup
                  ? /(\d)((\d{4}?)+)$/
                  : /(\d)((\d{3}?)+)$/);
            var s = e.split(t.aDec),
              u = o(s, 2),
              l = u[0],
              c = u[1];
            if (t.altDec && i(c)) {
              var h = e.split(t.altDec),
                g = o(h, 2);
              (l = g[0]), (c = g[1]);
            }
            if ("" !== t.aSep)
              for (; r.test(l); ) l = l.replace(r, "$1" + t.aSep + "$2");
            return (
              0 === t.mDec || i(c)
                ? (e = l)
                : (c.length > t.mDec && (c = c.substring(0, t.mDec)),
                  (e = l + t.aDec + c)),
              "p" === t.pSign &&
                (n && "l" === t.pNeg && (e = t.aNeg + t.aSign + e),
                n && "r" === t.pNeg && (e = t.aSign + t.aNeg + e),
                n && "s" === t.pNeg && (e = t.aSign + e + t.aNeg),
                n || (e = t.aSign + e)),
              "s" === t.pSign &&
                (n && "r" === t.pNeg && (e = e + t.aSign + t.aNeg),
                n && "l" === t.pNeg && (e = e + t.aNeg + t.aSign),
                n && "p" === t.pNeg && (e = t.aNeg + e + t.aSign),
                n || (e += t.aSign)),
              null !== t.nBracket &&
                (t.rawValue < 0 || "-" === e.charAt(0)) &&
                (e = F(e, t)),
              (t.trailingNegative = !1),
              e + t.aSuffix
            );
          }
          function q(e, t) {
            var i = void 0;
            switch (t) {
              case 0:
                i = /(\.(?:\d*[1-9])?)0*$/;
                break;
              case 1:
                i = /(\.\d(?:\d*[1-9])?)0*$/;
                break;
              default:
                i = new RegExp("(\\.\\d{" + t + "}(?:\\d*[1-9])?)0*");
            }
            return (
              (e = e.replace(i, "$1")), 0 === t && (e = e.replace(/\.$/, "")), e
            );
          }
          function z(e, t) {
            if (
              ((e = "" === e ? "0" : e.toString()),
              "N05" === t.mRound ||
                "CHF" === t.mRound ||
                "U05" === t.mRound ||
                "D05" === t.mRound)
            ) {
              switch (t.mRound) {
                case "N05":
                  e = (Math.round(20 * e) / 20).toString();
                  break;
                case "U05":
                  e = (Math.ceil(20 * e) / 20).toString();
                  break;
                default:
                  e = (Math.floor(20 * e) / 20).toString();
              }
              var i = void 0;
              return (i = y(e, ".")
                ? e.length - e.indexOf(".") < 3
                  ? e + "0"
                  : e
                : e + ".00");
            }
            var a = "",
              n = 0,
              r = "",
              o = void 0;
            (o = t.aPad ? t.mDec : 0),
              "-" === e.charAt(0) && ((r = "-"), (e = e.replace("-", ""))),
              e.match(/^\d/) || (e = "0" + e),
              "-" === r && 0 === Number(e) && (r = ""),
              ((Number(e) > 0 && "keep" !== t.lZero) ||
                (e.length > 0 && "allow" === t.lZero)) &&
                (e = e.replace(/^0*(\d)/, "$1"));
            var s = e.lastIndexOf("."),
              u = s === -1 ? e.length - 1 : s,
              l = e.length - 1 - u;
            if (l <= t.mDec) {
              if (((a = e), l < o)) {
                s === -1 && (a += t.aDec);
                for (var c = "000000"; l < o; )
                  (c = c.substring(0, o - l)), (a += c), (l += c.length);
              } else
                l > o
                  ? (a = q(a, o))
                  : 0 === l && 0 === o && (a = a.replace(/\.$/, ""));
              return 0 === Number(a) ? a : r + a;
            }
            var h = s + t.mDec,
              g = Number(e.charAt(h + 1)),
              p = "." === e.charAt(h) ? e.charAt(h - 1) % 2 : e.charAt(h) % 2,
              f = e.substring(0, h + 1).split("");
            if (
              (g > 4 && "S" === t.mRound) ||
              (g > 4 && "A" === t.mRound && "" === r) ||
              (g > 5 && "A" === t.mRound && "-" === r) ||
              (g > 5 && "s" === t.mRound) ||
              (g > 5 && "a" === t.mRound && "" === r) ||
              (g > 4 && "a" === t.mRound && "-" === r) ||
              (g > 5 && "B" === t.mRound) ||
              (5 === g && "B" === t.mRound && 1 === p) ||
              (g > 0 && "C" === t.mRound && "" === r) ||
              (g > 0 && "F" === t.mRound && "-" === r) ||
              (g > 0 && "U" === t.mRound)
            )
              for (n = f.length - 1; n >= 0; n -= 1)
                if ("." !== f[n]) {
                  if (((f[n] = +f[n] + 1), f[n] < 10)) break;
                  n > 0 && (f[n] = "0");
                }
            return (
              (f = f.slice(0, h + 1)),
              (a = q(f.join(""), o)),
              0 === Number(a) ? a : r + a
            );
          }
          function U(e, t, i) {
            var a = t.aDec,
              n = t.mDec;
            if (((e = "paste" === i ? z(e, t) : e), a && n)) {
              var r = e.split(a),
                s = o(r, 2),
                u = s[0],
                l = s[1];
              if (l && l.length > n)
                if (n > 0) {
                  var c = l.substring(0, n);
                  e = "" + u + a + c;
                } else e = u;
            }
            return e;
          }
          function K(e) {
            var t = {},
              i = void 0,
              a = void 0,
              n = void 0,
              r = void 0;
            if (
              (0 === e && 1 / e < 0 && (e = "-0"),
              (e = e.toString()),
              "-" === e.charAt(0) ? ((e = e.slice(1)), (t.s = -1)) : (t.s = 1),
              (i = e.indexOf(".")),
              i > -1 && (e = e.replace(".", "")),
              i < 0 && (i = e.length),
              (a = e.search(/[1-9]/i) === -1 ? e.length : e.search(/[1-9]/i)),
              (n = e.length),
              a === n)
            )
              (t.e = 0), (t.c = [0]);
            else {
              for (r = n - 1; "0" === e.charAt(r); r -= 1) n -= 1;
              for (n -= 1, t.e = i - a - 1, t.c = [], i = 0; a <= n; a += 1)
                (t.c[i] = +e.charAt(a)), (i += 1);
            }
            return t;
          }
          function Z(e, t) {
            var i = t.c,
              a = e.c,
              n = t.s,
              r = e.s,
              o = t.e,
              s = e.e;
            if (!i[0] || !a[0]) {
              var u = void 0;
              return (u = i[0] ? n : a[0] ? -r : 0);
            }
            if (n !== r) return n;
            var l = n < 0;
            if (o !== s) return (o > s) ^ l ? 1 : -1;
            for (
              n = -1, o = i.length, s = a.length, r = o < s ? o : s, n += 1;
              n < r;
              n += 1
            )
              if (i[n] !== a[n]) return (i[n] > a[n]) ^ l ? 1 : -1;
            var c = void 0;
            return (c = o === s ? 0 : (o > s) ^ l ? 1 : -1);
          }
          function G(e, t) {
            (e = e.toString()), (e = e.replace(",", "."));
            var i = K(t.vMin),
              a = K(t.vMax),
              n = K(e),
              r = void 0;
            switch (t.oLimits) {
              case "floor":
                r = [Z(i, n) > -1, !0];
                break;
              case "ceiling":
                r = [!0, Z(a, n) < 1];
                break;
              case "ignore":
                r = [!0, !0];
                break;
              default:
                r = [Z(i, n) > -1, Z(a, n) < 1];
            }
            return r;
          }
          function Q(t) {
            return (
              n(t) && (t = "#" + t.replace(/(:|\.|\[|]|,|=)/g, "\\$1")), e(t)
            );
          }
          function H(e, t) {
            var a =
                arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
              n = e.data("autoNumeric");
            n || ((n = {}), e.data("autoNumeric", n));
            var r = n.holder;
            return (
              ((i(r) && t) || a) && ((r = new Y(e.get(0), t)), (n.holder = r)),
              r
            );
          }
          function _(e) {
            (e.oDec = e.mDec),
              (e.oPad = e.aPad),
              (e.oBracket = e.nBracket),
              (e.oSep = e.aSep),
              (e.oSign = e.aSign),
              (e.oSuffix = e.aSuffix);
          }
          function W(e) {
            for (
              var t = e + "=", i = document.cookie.split(";"), a = "", n = 0;
              n < i.length;
              n += 1
            ) {
              for (a = i[n]; " " === a.charAt(0); )
                a = a.substring(1, a.length);
              if (0 === a.indexOf(t)) return a.substring(t.length, a.length);
            }
            return null;
          }
          function J() {
            var e = "modernizr";
            try {
              return (
                sessionStorage.setItem(e, e), sessionStorage.removeItem(e), !0
              );
            } catch (e) {
              return !1;
            }
          }
          function X(e, t, a) {
            if (t.aStor) {
              var n =
                  "" === e[0].name || i(e[0].name)
                    ? "AUTO_" + e[0].id
                    : "AUTO_" + decodeURIComponent(e[0].name),
                r = void 0,
                o = void 0;
              if (J() === !1)
                switch (a) {
                  case "set":
                    document.cookie =
                      n + "=" + t.rawValue + "; expires= ; path=/";
                    break;
                  case "wipe":
                    (r = new Date()),
                      r.setTime(r.getTime() + -864e5),
                      (o = "; expires=" + r.toUTCString()),
                      (document.cookie = n + "='' ;" + o + "; path=/");
                    break;
                  case "get":
                    return W(n);
                }
              else
                switch (a) {
                  case "set":
                    sessionStorage.setItem(n, t.rawValue);
                    break;
                  case "wipe":
                    sessionStorage.removeItem(n);
                    break;
                  case "get":
                    return sessionStorage.getItem(n);
                }
            }
          }
          function Y(t, i) {
            (this.settings = i),
              (this.that = t),
              (this.$that = e(t)),
              (this.formatted = !1),
              (this.settingsClone = V(this.$that, this.settings)),
              (this.value = t.value);
          }
          function ee() {
            var t =
                !(arguments.length > 0 && void 0 !== arguments[0]) ||
                arguments[0],
              i = arguments[1],
              a = Q(i),
              n = e("form").index(a),
              r = e("form:eq(" + n + ")")[0],
              u = [],
              l = [],
              c = /^(?:submit|button|image|reset|file)$/i,
              h = /^(?:input|select|textarea|keygen)/i,
              g = /^(?:checkbox|radio)$/i,
              p = /^(?:button|checkbox|color|date|datetime|datetime-local|email|file|image|month|number|password|radio|range|reset|search|submit|time|url|week)/i,
              f = 0;
            if (
              (e.each(r, function(e, t) {
                "" === t.name ||
                !h.test(t.localName) ||
                c.test(t.type) ||
                t.disabled ||
                (!t.checked && g.test(t.type))
                  ? l.push(-1)
                  : (l.push(f), f++);
              }),
              (f = 0),
              e.each(r, function(e, t) {
                "input" !== t.localName ||
                ("" !== t.type &&
                  "text" !== t.type &&
                  "hidden" !== t.type &&
                  "tel" !== t.type)
                  ? (u.push(-1),
                    "input" === t.localName && p.test(t.type) && f++)
                  : (u.push(f), f++);
              }),
              t)
            ) {
              var d = a.serializeArray();
              return (
                e.each(d, function(t, i) {
                  var a = e.inArray(t, l);
                  if (a > -1 && u[a] > -1) {
                    var r = e("form:eq(" + n + ") input:eq(" + u[a] + ")"),
                      o = r.data("autoNumeric");
                    "object" ===
                      ("undefined" == typeof o ? "undefined" : s(o)) &&
                      (i.value = r.autoNumeric("getLocalized").toString());
                  }
                }),
                d
              );
            }
            var v = (function() {
              var t = a.serialize(),
                i = t.split("&");
              return (
                e.each(i, function(t) {
                  var a = i[t].split("="),
                    r = o(a, 2),
                    c = r[0],
                    h = r[1],
                    g = e.inArray(t, l);
                  if (g > -1 && u[g] > -1) {
                    var p = e("form:eq(" + n + ") input:eq(" + u[g] + ")"),
                      f = p.data("autoNumeric");
                    if (
                      "object" ===
                        ("undefined" == typeof f ? "undefined" : s(f)) &&
                      null !== h
                    ) {
                      var d = p.autoNumeric("getLocalized").toString();
                      i[t] = c + "=" + d;
                    }
                  }
                }),
                { v: i.join("&") }
              );
            })();
            if ("object" === ("undefined" == typeof v ? "undefined" : s(v)))
              return v.v;
          }
          function te(e, t) {
            return (
              e.on("focusin.autoNumeric mouseenter.autoNumeric", function(i) {
                t = H(e);
                var a = t.settingsClone;
                if (
                  "focusin" === i.type ||
                  ("mouseenter" === i.type &&
                    !e.is(":focus") &&
                    "focus" === a.wEmpty)
                ) {
                  (a.onOff = !0),
                    null !== a.nBracket &&
                      "" !== a.aNeg &&
                      e.val(F(e.val(), a));
                  var n = void 0;
                  a.eDec
                    ? ((a.mDec = a.eDec), e.autoNumeric("set", a.rawValue))
                    : a.scaleDivisor
                    ? ((a.mDec = a.oDec), e.autoNumeric("set", a.rawValue))
                    : a.nSep
                    ? ((a.aSep = ""),
                      (a.aSign = ""),
                      (a.aSuffix = ""),
                      e.autoNumeric("set", a.rawValue))
                    : (n = B(e.val(), a)) !== a.rawValue &&
                      e.autoNumeric("set", n),
                    (t.inVal = e.val()),
                    (t.lastVal = t.inVal);
                  var r = $(t.inVal, a, !0);
                  null !== r && "" !== r && "focus" === a.wEmpty && e.val(r);
                }
              }),
              t
            );
          }
          function ie(e, t) {
            return (
              e.on("keydown.autoNumeric", function(i) {
                if (((t = H(e)), t.that.readOnly))
                  return (t.processed = !0), !0;
                if ((t.init(i), t.skipAlways(i))) return (t.processed = !0), !0;
                if (t.processAlways()) {
                  (t.processed = !0), t.formatQuick(i);
                  var a = e.val();
                  return (
                    a !== t.lastVal &&
                      t.settingsClone.throwInput &&
                      e.trigger("input"),
                    (t.lastVal = a),
                    (t.settingsClone.throwInput = !0),
                    i.preventDefault(),
                    !1
                  );
                }
                return (t.formatted = !1), !0;
              }),
              t
            );
          }
          function ae(e, t) {
            return (
              e.on("keypress.autoNumeric", function(i) {
                if (!i.shiftKey || i.keyCode !== d.Insert) {
                  t = H(e);
                  var a = t.processed;
                  if ((t.init(i), t.skipAlways(i))) return !0;
                  if (a) return i.preventDefault(), !1;
                  if (t.processAlways() || t.processKeypress()) {
                    t.formatQuick(i);
                    var n = e.val();
                    return (
                      n !== t.lastVal &&
                        t.settingsClone.throwInput &&
                        e.trigger("input"),
                      (t.lastVal = n),
                      (t.settingsClone.throwInput = !0),
                      void i.preventDefault()
                    );
                  }
                  t.formatted = !1;
                }
              }),
              t
            );
          }
          function ne(e, t, i) {
            return (
              e.on("keyup.autoNumeric", function(a) {
                (t = H(e)), t.init(a);
                var n = t.skipAlways(a),
                  r = t.kdCode;
                return (
                  (t.kdCode = 0),
                  delete t.valuePartsBeforePaste,
                  e[0].value === t.settingsClone.aSign
                    ? "s" === t.settingsClone.pSign
                      ? A(this, 0, 0)
                      : A(
                          this,
                          t.settingsClone.aSign.length,
                          t.settingsClone.aSign.length
                        )
                    : r === d.Tab && A(this, 0, e.val().length),
                  e[0].value === t.settingsClone.aSuffix && A(this, 0, 0),
                  "" === t.settingsClone.rawValue &&
                    "" !== t.settingsClone.aSign &&
                    "" !== t.settingsClone.aSuffix &&
                    A(this, 0, 0),
                  null !== t.settingsClone.eDec &&
                    t.settingsClone.aStor &&
                    X(e, i, "set"),
                  !!n ||
                    "" === this.value || void (t.formatted || t.formatQuick(a))
                );
              }),
              t
            );
          }
          function re(e, t) {
            return (
              e.on("focusout.autoNumeric mouseleave.autoNumeric", function() {
                if (!e.is(":focus")) {
                  t = H(e);
                  var i = e.val(),
                    a = i,
                    n = t.settingsClone;
                  if (
                    ((n.onOff = !1),
                    n.aStor && X(e, n, "set"),
                    n.nSep === !0 &&
                      ((n.aSep = n.oSep),
                      (n.aSign = n.oSign),
                      (n.aSuffix = n.oSuffix)),
                    null !== n.eDec &&
                      ((n.mDec = n.oDec),
                      (n.aPad = n.oPad),
                      (n.nBracket = n.oBracket)),
                    (i = B(i, n)),
                    "" !== i)
                  ) {
                    n.trailingNegative &&
                      ((i = "-" + i), (n.trailingNegative = !1));
                    var r = G(i, n),
                      s = o(r, 2),
                      u = s[0],
                      l = s[1];
                    null === $(i, n, !1) && u && l
                      ? ((i = j(i, n)),
                        (n.rawValue = i),
                        n.scaleDivisor &&
                          ((i /= n.scaleDivisor), (i = i.toString())),
                        (n.mDec =
                          n.scaleDivisor && n.scaleDecimal
                            ? +n.scaleDecimal
                            : n.mDec),
                        (i = z(i, n)),
                        (i = L(i, n)))
                      : (u || e.trigger("autoNumeric:minExceeded"),
                        l || e.trigger("autoNumeric:maxExceeded"),
                        (i = n.rawValue));
                  } else
                    "zero" === n.wEmpty
                      ? ((n.rawValue = "0"), (i = z("0", n)))
                      : (n.rawValue = "");
                  var c = $(i, n, !1);
                  null === c && (c = I(i, n)),
                    c !== a &&
                      ((c = n.scaleSymbol ? c + n.scaleSymbol : c), e.val(c)),
                    c !== t.inVal && (e.change(), delete t.inVal);
                }
              }),
              t
            );
          }
          function oe(e, t) {
            return (
              e.on("paste", function(i) {
                i.preventDefault(), (t = H(e));
                var a = e.autoNumeric("get"),
                  n = this.value || "",
                  r = this.selectionStart || 0,
                  o = this.selectionEnd || 0,
                  s = n.substring(0, r),
                  u = n.substring(o, n.length),
                  l = b(
                    i.originalEvent.clipboardData.getData("text/plain").holder
                  );
                if (N(l)) {
                  var c = b(s + Number(l).valueOf() + u, t);
                  N(c) &&
                    Number(a).valueOf() !== Number(c).valueOf() &&
                    (e.autoNumeric("set", c), e.trigger("input"));
                } else this.selectionStart = o;
              }),
              t
            );
          }
          function se(e, t) {
            return (
              e.closest("form").on("submit.autoNumeric", function() {
                if ((t = H(e))) {
                  var i = t.settingsClone;
                  i.unSetOnSubmit && e.val(i.rawValue);
                }
              }),
              t
            );
          }
          function ue(e) {
            var t = e.is(
              "input[type=text], input[type=hidden], input[type=tel], input:not([type])"
            );
            t ||
              "input" !== e.prop("tagName").toLowerCase() ||
              M(
                'The input type "' +
                  e.prop("type") +
                  '" is not supported by autoNumeric'
              );
            var i = e.prop("tagName").toLowerCase();
            return (
              "input" === i ||
                D(i, p) ||
                M("The <" + i + "> tag is not supported by autoNumeric"),
              t
            );
          }
          function le(t, i, n) {
            var r = !0;
            if (i) {
              var o = n.val();
              if (t.aForm && "" !== o && a(n.attr("value"))) {
                var s = parseFloat(o.replace(",", "."));
                isNaN(s) || 1 / 0 === s
                  ? M(
                      "The value [" +
                        o +
                        "] used in the input is not a valid value autoNumeric can work with."
                    )
                  : (n.autoNumeric("set", s), (r = !1));
              } else if (
                (null !== t.anDefault && t.anDefault.toString() !== o) ||
                (null === t.anDefault && "" !== o && o !== n.attr("value")) ||
                ("" !== o &&
                  "hidden" === n.attr("type") &&
                  !e.isNumeric(o.replace(",", ".")))
              ) {
                if (
                  (((null !== t.eDec && t.aStor) ||
                    (t.scaleDivisor && t.aStor)) &&
                    (t.rawValue = X(n, t, "get")),
                  !t.aStor)
                ) {
                  var u = void 0;
                  null !== t.nBracket && "" !== t.aNeg
                    ? ((t.onOff = !0), (u = F(o, t)))
                    : (u = o),
                    (t.rawValue =
                      ("s" === t.pNeg || ("s" === t.pSign && "p" !== t.pNeg)) &&
                      "" !== t.aNeg &&
                      y(o, "-")
                        ? "-" + B(u, t)
                        : B(u, t));
                }
                r = !1;
              }
              if ("" === o)
                switch (t.wEmpty) {
                  case "focus":
                    r = !1;
                    break;
                  case "always":
                    n.val(t.aSign), (r = !1);
                    break;
                  case "zero":
                    n.autoNumeric("set", "0"), (r = !1);
                }
              else r && o === n.attr("value") && n.autoNumeric("set", o);
            }
            D(n.prop("tagName").toLowerCase(), t.tagList) &&
              "" !== n.text() &&
              (null !== t.anDefault
                ? t.anDefault === n.text() && n.autoNumeric("set", n.text())
                : n.autoNumeric("set", n.text()));
          }
          function ce(e, t) {
            if (!i(e) && a(e.pNeg) && "" !== e.aSign)
              switch (t.pSign) {
                case "s":
                  t.pNeg = "p";
                  break;
                case "p":
                  t.pNeg = "r";
              }
          }
          function he(t, i) {
            var a = i.data("autoNumeric");
            if ("object" !== ("undefined" == typeof a ? "undefined" : s(a))) {
              var n = i.data();
              return (
                (a = e.extend({}, f, n, t, {
                  onOff: !1,
                  runOnce: !1,
                  rawValue: "",
                  trailingNegative: !1,
                  caretFix: !1,
                  throwInput: !0,
                  strip: !0,
                  tagList: p
                })),
                e.each(a, function(e, t) {
                  ("true" !== t && "false" !== t) || (a[e] = "true" === t),
                    "number" == typeof t &&
                      "aScale" !== e &&
                      (a[e] = t.toString());
                }),
                ce(t, a),
                (a.aNeg = a.vMin < 0 ? "-" : ""),
                h(a, !1),
                i.data("autoNumeric", a),
                a
              );
            }
            return null;
          }
          function ge(e, t) {
            return new CustomEvent(e, {
              detail: t,
              bubbles: !1,
              cancelable: !1
            });
          }
          function pe(e) {
            var t =
              arguments.length > 1 && void 0 !== arguments[1]
                ? arguments[1]
                : null;
            return document.dispatchEvent(ge(e, t));
          }
          Y.prototype = {
            init: function(e) {
              (this.value = this.that.value),
                (this.settingsClone = V(this.$that, this.settings)),
                (this.ctrlKey = e.ctrlKey),
                (this.cmdKey = e.metaKey),
                (this.shiftKey = e.shiftKey),
                (this.selection = C(this.that)),
                ("keydown" !== e.type && "keyup" !== e.type) ||
                  (this.kdCode = e.keyCode),
                (this.which = e.which),
                (this.processed = !1),
                (this.formatted = !1);
            },
            setSelection: function(e, t, a) {
              (e = Math.max(e, 0)),
                (t = Math.min(t, this.that.value.length)),
                (this.selection = { start: e, end: t, length: t - e }),
                (i(a) || a) && A(this.that, e, t);
            },
            setPosition: function(e, t) {
              this.setSelection(e, e, t);
            },
            getBeforeAfter: function() {
              var e = this.value,
                t = e.substring(0, this.selection.start),
                i = e.substring(this.selection.end, e.length);
              return [t, i];
            },
            getBeforeAfterStriped: function() {
              var e = this.settingsClone,
                t = this.getBeforeAfter(),
                i = o(t, 2),
                a = i[0],
                n = i[1];
              return (
                (a = B(a, this.settingsClone)),
                (n = B(n, this.settingsClone)),
                e.trailingNegative &&
                  !y(a, "-") &&
                  ((a = "-" + a), (n = "-" === n ? "" : n)),
                (e.trailingNegative = !1),
                [a, n]
              );
            },
            normalizeParts: function(e, t) {
              var i = this.settingsClone;
              if (
                ((e = B(e, i)),
                (t = B(t, i)),
                i.trailingNegative &&
                  !y(e, "-") &&
                  ((e = "-" + e), (i.trailingNegative = !1)),
                ("" !== e && e !== i.aNeg) ||
                  "deny" !== i.lZero ||
                  (t > "" && (t = t.replace(/^0*(\d)/, "$1"))),
                (this.newValue = e + t),
                i.aDec)
              ) {
                var a = this.newValue.match(
                  new RegExp("^" + i.aNegRegAutoStrip + "\\" + i.aDec)
                );
                a &&
                  ((e = e.replace(a[1], a[1] + "0")), (this.newValue = e + t));
              }
              return [e, t];
            },
            setValueParts: function(e, t, i) {
              var a = this.settingsClone,
                n = this.normalizeParts(e, t),
                r = G(this.newValue, a),
                s = o(r, 2),
                u = s[0],
                l = s[1],
                c = n[0].length;
              if (((this.newValue = n.join("")), u && l)) {
                this.newValue = U(this.newValue, a, i);
                var h = y(this.newValue, ",")
                  ? this.newValue.replace(",", ".")
                  : this.newValue;
                return (
                  "" === h || h === a.aNeg
                    ? (a.rawValue = "")
                    : (a.rawValue = h),
                  c > this.newValue.length && (c = this.newValue.length),
                  (this.value = this.newValue),
                  this.setPosition(c, !1),
                  !0
                );
              }
              return (
                u
                  ? l || this.$that.trigger("autoNumeric:maxExceeded")
                  : this.$that.trigger("autoNumeric:minExceeded"),
                !1
              );
            },
            signPosition: function() {
              var e = this.settingsClone,
                t = e.aSign,
                i = this.that;
              if (t) {
                var a = t.length;
                if ("p" === e.pSign) {
                  var n = e.aNeg && i.value && i.value.charAt(0) === e.aNeg;
                  return n ? [1, a + 1] : [0, a];
                }
                var r = i.value.length;
                return [r - a, r];
              }
              return [1e3, -1];
            },
            expandSelectionOnSign: function(e) {
              var t = this.signPosition(),
                i = this.selection;
              i.start < t[1] &&
                i.end > t[0] &&
                ((i.start < t[0] || i.end > t[1]) &&
                this.value
                  .substring(Math.max(i.start, t[0]), Math.min(i.end, t[1]))
                  .match(/^\s*$/)
                  ? i.start < t[0]
                    ? this.setSelection(i.start, t[0], e)
                    : this.setSelection(t[1], i.end, e)
                  : this.setSelection(
                      Math.min(i.start, t[0]),
                      Math.max(i.end, t[1]),
                      e
                    ));
            },
            checkPaste: function() {
              if (!i(this.valuePartsBeforePaste)) {
                var e = this.valuePartsBeforePaste,
                  t = this.getBeforeAfter(),
                  a = o(t, 2),
                  n = a[0],
                  r = a[1];
                delete this.valuePartsBeforePaste;
                var s =
                  n.substr(0, e[0].length) +
                  B(n.substr(e[0].length), this.settingsClone);
                this.setValueParts(s, r, "paste") ||
                  ((this.value = e.join("")),
                  this.setPosition(e[0].length, !1));
              }
            },
            skipAlways: function(e) {
              var t = this.kdCode,
                a = this.which,
                n = this.ctrlKey,
                r = this.cmdKey,
                o = this.shiftKey;
              if (
                ((n || r) &&
                  "keyup" === e.type &&
                  !i(this.valuePartsBeforePaste)) ||
                (o && t === d.Insert)
              )
                return this.checkPaste(), !1;
              if (
                (t >= d.F1 && t <= d.F12) ||
                (t >= d.Windows && t <= d.RightClick) ||
                (t >= d.Tab && t < d.Space) ||
                (t < d.Backspace && (0 === a || a === t)) ||
                t === d.NumLock ||
                t === d.ScrollLock ||
                t === d.Insert ||
                t === d.Command
              )
                return !0;
              if ((n || r) && t === d.a) {
                if (this.settings.sNumber) {
                  e.preventDefault();
                  var s = this.that.value.length,
                    u = this.settings.aSign.length,
                    l = y(this.that.value, "-") ? 1 : 0,
                    c = this.settings.aSuffix.length,
                    h = this.settings.pSign,
                    g = this.settings.pNeg,
                    p = void 0;
                  p = "s" === h ? 0 : "l" === g && 1 === l && u > 0 ? u + 1 : u;
                  var f = void 0;
                  if ("p" === h) f = s - c;
                  else
                    switch (g) {
                      case "l":
                        f = s - (c + u);
                        break;
                      case "r":
                        f = u > 0 ? s - (u + l + c) : s - (u + c);
                        break;
                      default:
                        f = s - (u + c);
                    }
                  A(this.that, p, f);
                }
                return !0;
              }
              if ((n || r) && (t === d.c || t === d.v || t === d.x))
                return (
                  "keydown" === e.type && this.expandSelectionOnSign(),
                  (t !== d.v && t !== d.Insert) ||
                    ("keydown" === e.type || "keypress" === e.type
                      ? i(this.valuePartsBeforePaste) &&
                        (this.valuePartsBeforePaste = this.getBeforeAfter())
                      : this.checkPaste()),
                  "keydown" === e.type || "keypress" === e.type || t === d.c
                );
              if (n || r) return !0;
              if (t === d.LeftArrow || t === d.RightArrow) {
                var v = this.settingsClone.aSep,
                  m = this.settingsClone.aDec,
                  S = this.selection.start,
                  N = this.that.value;
                return (
                  "keydown" !== e.type ||
                    this.shiftKey ||
                    (t !== d.LeftArrow ||
                    (N.charAt(S - 2) !== v && N.charAt(S - 2) !== m)
                      ? t !== d.RightArrow ||
                        (N.charAt(S + 1) !== v && N.charAt(S + 1) !== m) ||
                        this.setPosition(S + 1)
                      : this.setPosition(S - 1)),
                  !0
                );
              }
              return t >= d.PageDown && t <= d.DownArrow;
            },
            processTrailing: function(e) {
              var t = o(e, 2),
                i = t[0],
                a = t[1],
                n = this.settingsClone;
              return (
                "p" === n.pSign &&
                  "s" === n.pNeg &&
                  (8 === this.kdCode
                    ? ((n.caretFix =
                        this.selection.start >= this.value.indexOf(n.aSuffix) &&
                        "" !== n.aSuffix),
                      "-" === this.value.charAt(this.selection.start - 1)
                        ? (i = i.substring(1))
                        : this.selection.start <=
                            this.value.length - n.aSuffix.length &&
                          (i = i.substring(0, i.length - 1)))
                    : ((n.caretFix =
                        this.selection.start >= this.value.indexOf(n.aSuffix) &&
                        "" !== n.aSuffix),
                      this.selection.start >=
                        this.value.indexOf(n.aSign) + n.aSign.length &&
                        (a = a.substring(1, a.length)),
                      y(i, "-") &&
                        "-" === this.value.charAt(this.selection.start) &&
                        (i = i.substring(1)))),
                "s" === n.pSign &&
                  "l" === n.pNeg &&
                  ((n.caretFix =
                    this.selection.start >=
                    this.value.indexOf(n.aNeg) + n.aNeg.length),
                  8 === this.kdCode
                    ? this.selection.start ===
                        this.value.indexOf(n.aNeg) + n.aNeg.length &&
                      y(this.value, n.aNeg)
                      ? (i = i.substring(1))
                      : "-" !== i &&
                        (this.selection.start <= this.value.indexOf(n.aNeg) ||
                          !y(this.value, n.aNeg)) &&
                        (i = i.substring(0, i.length - 1))
                    : ("-" === i[0] && (a = a.substring(1)),
                      this.selection.start === this.value.indexOf(n.aNeg) &&
                        y(this.value, n.aNeg) &&
                        (i = i.substring(1)))),
                "s" === n.pSign &&
                  "r" === n.pNeg &&
                  ((n.caretFix =
                    this.selection.start >=
                    this.value.indexOf(n.aNeg) + n.aNeg.length),
                  8 === this.kdCode
                    ? this.selection.start ===
                      this.value.indexOf(n.aNeg) + n.aNeg.length
                      ? (i = i.substring(1))
                      : "-" !== i &&
                        this.selection.start <=
                          this.value.indexOf(n.aNeg) - n.aSign.length
                      ? (i = i.substring(0, i.length - 1))
                      : "" === i ||
                        y(this.value, n.aNeg) ||
                        (i = i.substring(0, i.length - 1))
                    : ((n.caretFix =
                        this.selection.start >= this.value.indexOf(n.aSign) &&
                        "" !== n.aSign),
                      this.selection.start === this.value.indexOf(n.aNeg) &&
                        (i = i.substring(1)),
                      (a = a.substring(1)))),
                [i, a]
              );
            },
            processAlways: function() {
              var e = this.settingsClone;
              if (this.kdCode === d.Backspace || this.kdCode === d.Delete) {
                var t = void 0,
                  i = void 0;
                if (this.selection.length) {
                  this.expandSelectionOnSign(!1);
                  var a = this.getBeforeAfterStriped(),
                    n = o(a, 2);
                  (t = n[0]), (i = n[1]), this.setValueParts(t, i);
                } else {
                  var r = this.getBeforeAfterStriped(),
                    s = o(r, 2);
                  if (
                    ((t = s[0]),
                    (i = s[1]),
                    "" === t && "" === i && (e.throwInput = !1),
                    (("p" === e.pSign && "s" === e.pNeg) ||
                      ("s" === e.pSign &&
                        ("l" === e.pNeg || "r" === e.pNeg))) &&
                      y(this.value, "-"))
                  ) {
                    var u = this.processTrailing([t, i]),
                      l = o(u, 2);
                    (t = l[0]), (i = l[1]);
                  } else
                    8 === this.kdCode
                      ? (t = t.substring(0, t.length - 1))
                      : (i = i.substring(1, i.length));
                  this.setValueParts(t, i);
                }
                return !0;
              }
              return !1;
            },
            processKeypress: function() {
              var e = this.settingsClone,
                t = String.fromCharCode(this.which),
                i = this.getBeforeAfterStriped(),
                a = o(i, 2),
                n = a[0],
                r = a[1];
              return (
                (e.throwInput = !0),
                t === e.aDec ||
                (e.altDec && t === e.altDec) ||
                (("." === t || "," === t) && this.kdCode === d.DotNumpad)
                  ? !e.mDec ||
                    !e.aDec ||
                    !(!e.aNeg || !y(r, e.aNeg)) ||
                      !!y(n, e.aDec) ||
                        r.indexOf(e.aDec) > 0 ||
                          (0 === r.indexOf(e.aDec) && (r = r.substr(1)),
                          this.setValueParts(n + e.aDec, r, null),
                          !0)
                  : ("-" !== t && "+" !== t) || "-" !== e.aNeg
                  ? t >= "0" && t <= "9"
                    ? (e.aNeg &&
                        "" === n &&
                        y(r, e.aNeg) &&
                        ((n = e.aNeg), (r = r.substring(1, r.length))),
                      e.vMax <= 0 &&
                        e.vMin < e.vMax &&
                        !y(this.value, e.aNeg) &&
                        "0" !== t &&
                        (n = e.aNeg + n),
                      this.setValueParts(n + t, r, null),
                      !0)
                    : ((e.throwInput = !1), !0)
                  : !e ||
                    (("p" === e.pSign && "s" === e.pNeg) ||
                    ("s" === e.pSign && "p" !== e.pNeg)
                      ? ("" === n &&
                          y(r, e.aNeg) &&
                          ((n = e.aNeg), (r = r.substring(1, r.length))),
                        (n =
                          "-" === n.charAt(0) || y(n, e.aNeg)
                            ? n.substring(1, n.length)
                            : "-" === t
                            ? e.aNeg + n
                            : n))
                      : ("" === n &&
                          y(r, e.aNeg) &&
                          ((n = e.aNeg), (r = r.substring(1, r.length))),
                        (n =
                          n.charAt(0) === e.aNeg
                            ? n.substring(1, n.length)
                            : "-" === t
                            ? e.aNeg + n
                            : n)),
                    this.setValueParts(n, r, null),
                    !0)
              );
            },
            formatQuick: function(t) {
              var i = this,
                a = this.settingsClone,
                n = this.value,
                r = t.keyCode,
                s = this.getBeforeAfterStriped(),
                u = o(s, 1),
                l = u[0];
              if (
                ("" === a.aSep || ("" !== a.aSep && !y(n, a.aSep))) &&
                ("" === a.aSign || ("" !== a.aSign && !y(n, a.aSign)))
              ) {
                var c = n.split(a.aDec),
                  h = o(c, 1),
                  g = h[0],
                  p = "";
                y(g, "-") &&
                  ((p = "-"),
                  (g = g.replace("-", "")),
                  (l = l.replace("-", ""))),
                  "" === p &&
                    g.length > a.mIntPos &&
                    "0" === l.charAt(0) &&
                    (l = l.slice(1)),
                  "-" === p &&
                    g.length > a.mIntNeg &&
                    "0" === l.charAt(0) &&
                    (l = l.slice(1)),
                  (l = p + l);
              }
              var f = I(this.value, this.settingsClone),
                v = f.length;
              if (f) {
                var m = l.split("");
                ("s" === a.pNeg || ("s" === a.pSign && "p" !== a.pNeg)) &&
                  "-" === m[0] &&
                  "" !== a.aNeg &&
                  (m.shift(),
                  "s" !== a.pSign ||
                    "l" !== a.pNeg ||
                    (r !== d.Backspace &&
                      this.kdCode !== d.Backspace &&
                      r !== d.Delete &&
                      this.kdCode !== d.Delete) ||
                    !a.caretFix ||
                    (m.push("-"), (a.caretFix = "keydown" === t.type)),
                  "p" !== a.pSign ||
                    "s" !== a.pNeg ||
                    (r !== d.Backspace &&
                      this.kdCode !== d.Backspace &&
                      r !== d.Delete &&
                      this.kdCode !== d.Delete) ||
                    !a.caretFix ||
                    (m.push("-"), (a.caretFix = "keydown" === t.type)),
                  "s" !== a.pSign ||
                    "r" !== a.pNeg ||
                    (r !== d.Backspace &&
                      this.kdCode !== d.Backspace &&
                      r !== d.Delete &&
                      this.kdCode !== d.Delete) ||
                    !a.caretFix ||
                    !(function() {
                      var n = a.aSign.split(""),
                        o = [
                          "\\",
                          "^",
                          "$",
                          ".",
                          "|",
                          "?",
                          "*",
                          "+",
                          "(",
                          ")",
                          "["
                        ],
                        s = [];
                      e.each(n, function(e, t) {
                        (t = n[e]), D(t, o) ? s.push("\\" + t) : s.push(t);
                      }),
                        (r !== d.Backspace && i.kdCode !== d.Backspace) ||
                          s.push("-"),
                        m.push(s.join("")),
                        (a.caretFix = "keydown" === t.type);
                    })());
                for (var S = 0; S < m.length; S++)
                  m[S].match("\\d") || (m[S] = "\\" + m[S]);
                var N = new RegExp("^.*?" + m.join(".*?")),
                  b = f.match(N);
                b
                  ? ((v = b[0].length),
                    ((0 === v && f.charAt(0) !== a.aNeg) ||
                      (1 === v && f.charAt(0) === a.aNeg)) &&
                      a.aSign &&
                      "p" === a.pSign &&
                      (v =
                        this.settingsClone.aSign.length +
                        ("-" === f.charAt(0) ? 1 : 0)))
                  : (a.aSign && "s" === a.pSign && (v -= a.aSign.length),
                    a.aSuffix && (v -= a.aSuffix.length));
              }
              (this.that.value = f), this.setPosition(v), (this.formatted = !0);
            }
          };
          var fe = {
            init: function(i) {
              return this.each(function() {
                var a = e(this),
                  n = ue(a),
                  r = he(i, a);
                if (t(r)) return this;
                _(r);
                var o = H(a, r);
                (r.mDec =
                  r.scaleDivisor && r.scaleDecimal ? r.scaleDecimal : r.mDec),
                  r.runOnce === !1 && r.aForm && le(r, n, a),
                  (r.runOnce = !0),
                  n &&
                    ((o = te(a, o)),
                    (o = re(a, o)),
                    (o = ie(a, o)),
                    (o = ae(a, o)),
                    (o = ne(a, o, r)),
                    (o = oe(a, o)),
                    se(a, o));
              });
            },
            destroy: function() {
              return e(this).each(function() {
                var e = Q(this),
                  t = e.data("autoNumeric");
                "object" === ("undefined" == typeof t ? "undefined" : s(t)) &&
                  (e.val(""),
                  X(e, t, "wipe"),
                  e.removeData("autoNumeric"),
                  e.off(".autoNumeric"));
              });
            },
            wipe: function() {
              return e(this).each(function() {
                var e = Q(this),
                  t = e.data("autoNumeric");
                "object" === ("undefined" == typeof t ? "undefined" : s(t)) &&
                  (e.val(""), (t.rawValue = ""), X(e, t, "wipe"));
              });
            },
            update: function(t) {
              return e(this).each(function() {
                var i = Q(this),
                  a = i.data("autoNumeric");
                "object" !== ("undefined" == typeof a ? "undefined" : s(a)) &&
                  M(
                    'Initializing autoNumeric is required prior to calling the "update" method'
                  );
                var n = i.autoNumeric("get");
                if (
                  ((a = e.extend(a, t)),
                  a.scaleDivisor &&
                    (a.mDec = a.scaleDecimal ? a.scaleDecimal : a.mDec),
                  _(a),
                  H(i, a, !0),
                  a.aDec === a.aSep &&
                    M(
                      'autoNumeric will not function properly when the decimal character aDec: "' +
                        a.aDec +
                        '" and thousand separator aSep: "' +
                        a.aSep +
                        '" are the same character'
                    ),
                  ce(t, a),
                  i.data("autoNumeric", a),
                  "" !== i.val() || "" !== i.text())
                )
                  return i.autoNumeric("set", n);
              });
            },
            set: function(t) {
              return e(this).each(function() {
                if (null !== t && !i(t)) {
                  var a = Q(this),
                    n = a.data("autoNumeric"),
                    r = a.is(
                      "input[type=text], input[type=hidden], input[type=tel], input:not([type])"
                    ),
                    u = t.toString();
                  if (
                    ("object" !==
                      ("undefined" == typeof n ? "undefined" : s(n)) &&
                      M(
                        'Initializing autoNumeric is required prior to calling the "set" method'
                      ),
                    (u = E(u)),
                    !e.isNumeric(Number(u)))
                  )
                    return (
                      O(
                        'The value "' +
                          u +
                          '" being "set" is not numeric and therefore cannot be used appropriately.'
                      ),
                      a.val("")
                    );
                  if ("" === u) return a.val("");
                  var l = G(u, n),
                    c = o(l, 2),
                    h = c[0],
                    g = c[1];
                  if (!h || !g) {
                    (n.rawValue = ""), X(a, n, "wipe");
                    var p = u;
                    return (
                      (u = ""),
                      h || a.trigger("autoNumeric:minExceeded"),
                      g || a.trigger("autoNumeric:maxExceeded"),
                      M(
                        "The value [" +
                          p +
                          "] being set falls outside of the vMin [" +
                          n.vMin +
                          "] and vMax [" +
                          n.vMax +
                          "] range set for this element"
                      ),
                      a.val("")
                    );
                  }
                  return (
                    r && (n.eDec || n.scaleDivisor) && (n.rawValue = u),
                    (r || D(a.prop("tagName").toLowerCase(), n.tagList)) &&
                      (n.scaleDivisor &&
                        !n.onOff &&
                        ((u /= n.scaleDivisor),
                        (u = u.toString()),
                        (n.mDec = n.scaleDecimal ? n.scaleDecimal : n.mDec)),
                      (u = z(u, n)),
                      null === n.eDec &&
                        null === n.scaleDivisor &&
                        (n.rawValue = u),
                      (u = L(u, n)),
                      (u = I(u, n))),
                    n.aStor && (n.eDec || n.scaleDivisor) && X(a, n, "set"),
                    !n.onOff && n.scaleSymbol && (u += n.scaleSymbol),
                    r
                      ? a.val(u)
                      : !!D(a.prop("tagName").toLowerCase(), n.tagList) &&
                        a.text(u)
                  );
                }
              });
            },
            unSet: function() {
              return e(this).each(function() {
                var e = Q(this),
                  t = e.data("autoNumeric");
                "object" === ("undefined" == typeof t ? "undefined" : s(t)) &&
                  ((t.onOff = !0), e.val(e.autoNumeric("getLocalized")));
              });
            },
            reSet: function() {
              return e(this).each(function() {
                var e = Q(this),
                  t = e.data("autoNumeric");
                "object" === ("undefined" == typeof t ? "undefined" : s(t)) &&
                  e.autoNumeric("set", e.val());
              });
            },
            get: function() {
              var e = Q(this),
                t = e.is(
                  "input[type=text], input[type=hidden], input[type=tel], input:not([type])"
                ),
                i = e.data("autoNumeric");
              "object" !== ("undefined" == typeof i ? "undefined" : s(i)) &&
                M(
                  'Initializing autoNumeric is required prior to calling the "get" method'
                );
              var a = "";
              if (
                (t
                  ? (a = e.eq(0).val())
                  : D(e.prop("tagName").toLowerCase(), i.tagList)
                  ? (a = e.eq(0).text())
                  : M(
                      'The "<' +
                        e.prop("tagName").toLowerCase() +
                        '>" tag is not supported by autoNumeric'
                    ),
                i.eDec || i.scaleDivisor)
              )
                a = i.rawValue;
              else {
                if (!/\d/.test(a) && 0 !== Number(a) && "focus" === i.wEmpty)
                  return "";
                "" !== a &&
                  null !== i.nBracket &&
                  ((i.onOff = !0), (a = F(a, i))),
                  (i.runOnce || i.aForm === !1) && (a = B(a, i)),
                  (a = j(a, i));
              }
              return a;
            },
            getLocalized: function() {
              var e = Q(this),
                t = e.autoNumeric("get"),
                i = e.data("autoNumeric");
              return (
                0 === Number(t) && "keep" !== i.lZero && (t = "0"),
                R(t, i.outputType)
              );
            },
            getFormatted: function() {
              return (
                (this.hasOwnProperty("0") && "value" in this[0]) ||
                  M("Unable to get the formatted string from the element."),
                this[0].value
              );
            },
            getString: function() {
              return ee(!1, this);
            },
            getArray: function() {
              return ee(!0, this);
            },
            getSettings: function() {
              var e = Q(this);
              return e.eq(0).data("autoNumeric");
            }
          };
          (e.fn.autoNumeric = function(e) {
            if (fe[e]) {
              for (
                var t = arguments.length, i = Array(t > 1 ? t - 1 : 0), a = 1;
                a < t;
                a++
              )
                i[a - 1] = arguments[a];
              return fe[e].apply(this, i);
            }
            return "object" !==
              ("undefined" == typeof e ? "undefined" : s(e)) && e
              ? void M('Method "' + e + '" is not supported by autoNumeric')
              : fe.init.apply(this, [e]);
          }),
            (c = function() {
              return f;
            }),
            (e.fn.autoNumeric.defaults = f),
            (u = function(t, a) {
              if (i(t) || null === t) return null;
              var n = e.extend({}, f, { strip: !1 }, a);
              if (
                ((t = t.toString()),
                (t = E(t)),
                Number(t) < 0 && (n.aNeg = "-"),
                null === n.mDec)
              ) {
                var r = n.vMax.toString().split("."),
                  s =
                    n.vMin || 0 === n.vMin ? n.vMin.toString().split(".") : [];
                n.mDec = P(s, r);
              }
              var u = G(t, n),
                l = o(u, 2),
                c = l[0],
                h = l[1];
              return (
                (c && h) ||
                  (pe("autoFormat.autoNumeric", "Range test failed"),
                  M(
                    "The value [" +
                      t +
                      "] being set falls outside of the vMin [" +
                      n.vMin +
                      "] and vMax [" +
                      n.vMax +
                      "] range set for this element"
                  )),
                (t = z(t, n)),
                (t = L(t, n)),
                (t = I(t, n))
              );
            }),
            (e.fn.autoFormat = u),
            (l = function(t, a) {
              if (i(t) || null === t) return null;
              var n = e.extend({}, f, { strip: !1 }, a),
                r = "-0123456789\\" + n.aDec,
                o = new RegExp("[^" + r + "]", "gi");
              return (
                (t = t.toString()),
                "-" === t.charAt(0)
                  ? (n.aNeg = "-")
                  : n.nBracket &&
                    n.nBracket.split(",")[0] === t.charAt(0) &&
                    ((n.aNeg = "-"), (n.onOff = !0), (t = F(t, n))),
                (t = t.replace(o, "")),
                (t = t.replace(",", ".")),
                (t = R(t, n.outputType))
              );
            }),
            (e.fn.autoUnformat = l),
            (h = function(i) {
              var o =
                  !(arguments.length > 1 && void 0 !== arguments[1]) ||
                  arguments[1],
                s = !0;
              (a(i) || !m(i) || S(i)) &&
                M(
                  "The userOptions are invalid ; it should be a valid object, [" +
                    i +
                    "] given."
                );
              var u = void 0;
              u = o ? e.extend({}, f, i) : i;
              var l = /^[0-9]+$/,
                c = /[0-9]+/,
                h = /^-?[0-9]+(\.?[0-9]+)?$/,
                g = /^[0-9]+(\.?[0-9]+)?$/;
              D(u.aSep, [",", ".", " ", ""]) ||
                M(
                  "The thousand separator character option 'aSep' is invalid ; it should be ',', '.', ' ' or empty (''), [" +
                    u.aSep +
                    "] given."
                ),
                v(u.nSep) ||
                  r(u.nSep) ||
                  M(
                    "The 'nSep' option is invalid ; it should be either 'false' or 'true', [" +
                      u.nSep +
                      "] given."
                  ),
                l.test(u.dGroup) ||
                  M(
                    "The digital grouping for thousand separator option 'dGroup' is invalid ; it should be a positive integer, [" +
                      u.dGroup +
                      "] given."
                  ),
                D(u.aDec, [",", "."]) ||
                  M(
                    "The decimal separator character option 'aDec' is invalid ; it should be '.' or ',', [" +
                      u.aDec +
                      "] given."
                  ),
                u.aDec === u.aSep &&
                  M(
                    "autoNumeric will not function properly when the decimal character 'aDec' [" +
                      u.aDec +
                      "] and the thousand separator 'aSep' [" +
                      u.aSep +
                      "] are the same character."
                  ),
                t(u.altDec) ||
                  n(u.altDec) ||
                  M(
                    "The alternate decimal separator character option 'altDec' is invalid ; it should be a string, [" +
                      u.altDec +
                      "] given."
                  ),
                "" === u.aSign ||
                  n(u.aSign) ||
                  M(
                    "The currency symbol option 'aSign' is invalid ; it should be a string, [" +
                      u.aSign +
                      "] given."
                  ),
                D(u.pSign, ["p", "s"]) ||
                  M(
                    "The placement of the currency sign option 'pSign' is invalid ; it should either be 'p' (prefix) or 's' (suffix), [" +
                      u.pSign +
                      "] given."
                  ),
                D(u.pNeg, ["p", "s", "l", "r"]) ||
                  M(
                    "The placement of the negative sign option 'pNeg' is invalid ; it should either be 'p' (prefix), 's' (suffix), 'l' (left) or 'r' (right), [" +
                      u.pNeg +
                      "] given."
                  ),
                (!n(u.aSuffix) ||
                  ("" !== u.aSuffix &&
                    (y(u.aSuffix, "-") || c.test(u.aSuffix)))) &&
                  M(
                    "The additional suffix option 'aSuffix' is invalid ; it should not contains the negative sign '-' nor any numerical characters, [" +
                      u.aSuffix +
                      "] given."
                  ),
                t(u.oLimits) ||
                  D(u.oLimits, ["ceiling", "floor", "ignore"]) ||
                  M(
                    "The override min & max limits option 'oLimits' is invalid ; it should either be 'ceiling', 'floor' or 'ignore', [" +
                      u.oLimits +
                      "] given."
                  ),
                (n(u.vMax) && h.test(u.vMax)) ||
                  M(
                    "The maximum possible value option 'vMax' is invalid ; it should be a string that represents a positive or negative number, [" +
                      u.vMax +
                      "] given."
                  ),
                (n(u.vMin) && h.test(u.vMin)) ||
                  M(
                    "The minimum possible value option 'vMin' is invalid ; it should be a string that represents a positive or negative number, [" +
                      u.vMin +
                      "] given."
                  ),
                parseFloat(u.vMin) > parseFloat(u.vMax) &&
                  M(
                    "The minimum possible value option is greater than the maximum possible value option ; 'vMin' [" +
                      u.vMin +
                      "] should be smaller than 'vMax' [" +
                      u.vMax +
                      "]."
                  ),
                t(u.mDec) ||
                  (n(u.mDec) && l.test(u.mDec)) ||
                  M(
                    "The maximum number of decimal places option 'mDec' is invalid ; it should be a positive integer, [" +
                      u.mDec +
                      "] given."
                  ),
                u.aPad ||
                  t(u.mDec) ||
                  O(
                    "Setting 'aPad' to [false] will override the current 'mDec' setting [" +
                      u.mDec +
                      "].",
                    s
                  );
              var p = k(u.vMin),
                d = k(u.vMax);
              (p = t(p) ? 0 : p), (d = t(d) ? 0 : d);
              var N = Math.max(p, d);
              t(u.mDec) ||
                (!x(u.vMin) && !x(u.vMax)) ||
                N === Number(u.mDec) ||
                O(
                  "Setting 'mDec' to [" +
                    u.mDec +
                    "] will override the decimals declared in 'vMin' [" +
                    u.vMin +
                    "] and 'vMax' [" +
                    u.vMax +
                    "].",
                  s
                ),
                t(u.eDec) ||
                  (n(u.eDec) && l.test(u.eDec)) ||
                  M(
                    "The number of expanded decimal places option 'eDec' is invalid ; it should be a positive integer, [" +
                      u.eDec +
                      "] given."
                  ),
                !t(u.eDec) &&
                  !t(u.mDec) &&
                  Number(u.mDec) < Number(u.eDec) &&
                  M(
                    "autoNumeric will not function properly when the extended decimal places 'eDec' [" +
                      u.eDec +
                      "] is greater than the 'mDec' [" +
                      u.mDec +
                      "] value."
                  ),
                t(u.scaleDivisor) ||
                  g.test(u.scaleDivisor) ||
                  M(
                    "The scale divisor option 'scaleDivisor' is invalid ; it should be a positive number, preferably an integer, [" +
                      u.scaleDivisor +
                      "] given."
                  ),
                t(u.scaleDecimal) ||
                  l.test(u.scaleDecimal) ||
                  M(
                    "The scale number of decimals option 'scaleDecimal' is invalid ; it should be a positive integer, [" +
                      u.scaleDecimal +
                      "] given."
                  ),
                t(u.scaleSymbol) ||
                  n(u.scaleSymbol) ||
                  M(
                    "The scale symbol option 'scaleSymbol' is invalid ; it should be a string, [" +
                      u.scaleSymbol +
                      "] given."
                  ),
                v(u.aStor) ||
                  r(u.aStor) ||
                  M(
                    "The save to session storage option 'aStor' is invalid ; it should be either 'false' or 'true', [" +
                      u.aStor +
                      "] given."
                  ),
                D(u.mRound, [
                  "S",
                  "A",
                  "s",
                  "a",
                  "B",
                  "U",
                  "D",
                  "C",
                  "F",
                  "N05",
                  "CHF",
                  "U05",
                  "D05"
                ]) ||
                  M(
                    "The rounding method option 'mRound' is invalid ; it should either be 'S', 'A', 's', 'a', 'B', 'U', 'D', 'C', 'F', 'N05', 'CHF', 'U05' or 'D05' (cf. documentation), [" +
                      u.mRound +
                      "] given."
                  ),
                v(u.aPad) ||
                  r(u.aPad) ||
                  M(
                    "The control decimal padding option 'aPad' is invalid ; it should be either 'false' or 'true', [" +
                      u.aPad +
                      "] given."
                  ),
                t(u.nBracket) ||
                  D(u.nBracket, ["(,)", "[,]", "<,>", "{,}"]) ||
                  M(
                    "The brackets for negative values option 'nBracket' is invalid ; it should either be '(,)', '[,]', '<,>' or '{,}', [" +
                      u.nBracket +
                      "] given."
                  ),
                D(u.wEmpty, ["focus", "press", "always", "zero"]) ||
                  M(
                    "The display on empty string option 'wEmpty' is invalid ; it should either be 'focus', 'press', 'always' or 'zero', [" +
                      u.wEmpty +
                      "] given."
                  ),
                D(u.lZero, ["allow", "deny", "keep"]) ||
                  M(
                    "The leading zero behavior option 'lZero' is invalid ; it should either be 'allow', 'deny' or 'keep', [" +
                      u.lZero +
                      "] given."
                  ),
                v(u.aForm) ||
                  r(u.aForm) ||
                  M(
                    "The format on initialization option 'aForm' is invalid ; it should be either 'false' or 'true', [" +
                      u.aForm +
                      "] given."
                  ),
                v(u.sNumber) ||
                  r(u.sNumber) ||
                  M(
                    "The select number only option 'sNumber' is invalid ; it should be either 'false' or 'true', [" +
                      u.sNumber +
                      "] given."
                  ),
                t(u.anDefault) ||
                  "" === u.anDefault ||
                  h.test(u.anDefault) ||
                  M(
                    "The unformatted default value option 'anDefault' is invalid ; it should be a string that represents a positive or negative number, [" +
                      u.anDefault +
                      "] given."
                  ),
                v(u.unSetOnSubmit) ||
                  r(u.unSetOnSubmit) ||
                  M(
                    "The remove formatting on submit option 'unSetOnSubmit' is invalid ; it should be either 'false' or 'true', [" +
                      u.unSetOnSubmit +
                      "] given."
                  ),
                t(u.outputType) ||
                  D(u.outputType, [
                    "string",
                    "number",
                    ".",
                    "-.",
                    ",",
                    "-,",
                    ".-",
                    ",-"
                  ]) ||
                  M(
                    "The custom locale format option 'outputType' is invalid ; it should either be null, 'string', 'number', '.', '-.', ',', '-,', '.-' or ',-', [" +
                      u.outputType +
                      "] given."
                  ),
                v(u.debug) ||
                  r(u.debug) ||
                  M(
                    "The debug option 'debug' is invalid ; it should be either 'false' or 'true', [" +
                      u.debug +
                      "] given."
                  );
            }),
            (e.fn.validate = h),
            (g = function(e) {
              var t = !0;
              try {
                h(e);
              } catch (e) {
                t = !1;
              }
              return t;
            }),
            (function() {
              function e(e, t) {
                t = t || { bubbles: !1, cancelable: !1, detail: void 0 };
                var i = document.createEvent("CustomEvent");
                return (
                  i.initCustomEvent(e, t.bubbles, t.cancelable, t.detail), i
                );
              }
              return (
                "function" != typeof window.CustomEvent &&
                ((e.prototype = window.Event.prototype),
                void (window.CustomEvent = e))
              );
            })();
        }),
          (t.default = {
            format: u,
            unFormat: l,
            getDefaultConfig: c,
            validate: h,
            areSettingsValid: g
          });
      }.call(window));
    },
    function(t, i) {
      t.exports = e;
    }
  ]);
});
