import { domReady } from "../utils";

import "../libs/autonumeric/autoNumeric-2.0";
import "../libs/autonumeric/autonumeric_ujs";

const initAutoNumeric = () => {
  // INITIALIZER
  // $(document).ready(function() {
  //   $(".currency_brl").AutonumericRails("init", {
  //     aSign: "R$ ",
  //     aSep: ".",
  //     aDec: ","
  //   });
  // });
};

export default domReady(initAutoNumeric);
