import Vue from "vue";

let components = [
  "BatchSummary",
  "EvaluatedBrand",
  "EvaluatedReceivedLost",
  "EvaluationApollo",
  "EvaluationDealership",
  "EvaluationGroup",
  "EvaluationMonth",
  "EvaluationSeller",
  "EvaluationSummary",
  "Loading",
  "Painel",
  "ReceivedBrand",
  "ReceivedSeller",
  "StackedBars"
];

for (let component of components) {
  Vue.component(component, () => import(`./sira/${component}.vue`));
}
