import { domReady } from "../../utils";
import axios from "axios";
import {
  enableSelect,
  disableSelect,
  clearSelectElTarget,
  clearEl
} from "./concerns";

const main = () => {
  const stateEl = document.querySelector("#state_id");
  const cityEl = document.querySelector("#city_id");

  if (!stateEl && !cityEl) {
    return;
  }

  const BASE_URL = "searches";

  const init = () => {
    if (stateEl.value === "") {
      clearSelectElTarget(cityEl, "Selecione primeiro o Estado");
      disableSelect(cityEl);
    }

    stateHandler();
  };

  const stateHandler = () => {
    stateEl.addEventListener("change", function() {
      let state_id = this.value;

      clearSelectElTarget(cityEl, "Selecione primeiro o Estado");
      disableSelect(cityEl);

      if (state_id) {
        axios
          .get(`${BASE_URL}/cities?q[state_id_eq]=${state_id}`)
          .then(function(response) {
            // Limpando OPTIONS
            clearEl(cityEl);

            // Populando OPTIONS
            if (response.data.cities.length > 0) {
              enableSelect(cityEl);

              cityEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Cidade</option>`
              );

              response.data.cities.map(current_data => {
                cityEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(cityEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(cityEl, "Selecione primeiro o Estado");
      }
    });
  };

  init();
};

export default domReady(main);
