export default {
  state: () => ({
    id: null,
    license_plate: "".toUpperCase(),
    chassi: "",
    renavam: "",
    mileage: null,
    type: null,
    manufacture_year: null,
    model_year: null,
    make_id: null,
    model_id: null,
    trim_id: null,
    fuel_id: null,
    color_id: null,
    doors: [],
    engine: "",
    optionals_vehicle_ids: [],
    city_id: null,
    lead_id: null,
    client_id: null,
    makes: [],
    models: [],
    optionals: [],
    trims: []
  }),
  mutations: {
    setVehicleId(state, payload) {
      state.id = payload;
    },
    setLicensePlate(state, payload) {
      state.license_plate = payload;
    },
    setChassi(state, payload) {
      state.chassi = payload;
    },
    setRenavam(state, payload) {
      state.renavam = payload;
    },
    setMileage(state, payload) {
      state.mileage = payload;
    },
    setType(state, payload) {
      state.type = payload;
    },
    setManufactureYear(state, payload) {
      state.manufacture_year = payload;
    },
    setModelYear(state, payload) {
      state.model_year = payload;
    },
    setMakeId(state, payload) {
      state.make_id = payload;
    },
    setModelId(state, payload) {
      state.model_id = payload;
    },
    setTrimId(state, payload) {
      state.trim_id = payload;
    },
    setFuelId(state, payload) {
      state.fuel_id = payload;
    },
    setColorId(state, payload) {
      state.color_id = payload;
    },
    setDoors(state, payload) {
      state.doors = payload;
    },
    setEngine(state, payload) {
      state.engine = payload;
    },
    setOptionalsVehicleIds(state, payload) {
      state.optionals_vehicle_ids = payload;
    },
    setCityId(state, payload) {
      state.city_id = payload;
    },
    setLeadId(state, payload) {
      state.lead_id = payload;
    },
    setClientId(state, payload) {
      state.client_id = payload;
    },
    setVehicleForm(state, payload) {
      state.id = payload.id;
      state.license_plate = payload.license_plate;
      state.chassi = payload.chassi;
      state.renavam = payload.renavam;
      state.mileage = payload.mileage;
      state.type = payload.type;
      state.manufacture_year = payload.manufacture_year;
      state.model_year = payload.model_year;
      state.make_id = payload.make_id;
      state.model_id = payload.model_id;
      state.trim_id = payload.trim_id;
      state.fuel_id = payload.fuel_id;
      state.color_id = payload.color_id;
      state.doors = payload.doors;
      state.engine = payload.engine;
      state.optionals_vehicle_ids = payload.optional_ids;
      state.city_id = payload.city_id;
      state.lead_id = payload.lead_id;
    },
    setMakes(state, payload) {
      state.makes = payload;
    },
    setModels(state, payload) {
      state.models = payload;
    },
    setOptionals(state, payload) {
      state.optionals = payload;
    },
    setTrims(state, payload) {
      state.trims = payload;
    }
  },
  actions: {}
};
