$(document).ready(function() {
  const kind_ds = document.getElementById("attendance_kind_ds_box")

  if (kind_ds) {
    // DISABILITA KIND_DS SE O KIND NÃO ESTIVER MARCADO
    if ($("input[name='attendance[kind]']:checked")) {
      kind_ds_checker();
    } else {
      kind_ds.style.display = "none";
    }

    // EXIBE KIND_DS SE KIND MARCADO COMO VENDA DIRETA
    $("input[name='attendance[kind]']").on("change", function() {
      kind_ds_checker()
    });
    
    function kind_ds_checker() {
      const kind_ds_value = document.querySelector("input[name='attendance[kind]']:checked")

      kind_ds_value
        ? kind_ds.style.display = (kind_ds_value.value == "direct_selling") ? "block" : "none"
        : kind_ds.style.display = "none";
    }
  }
});
