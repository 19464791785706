import { Chart } from "chart.js";
import * as Utils from "./utils";

window.evaluationMonthReports = () => {
  let labels_chart = [];
  let datasets = [];

  if ($("#evaluationMonthReports").length > 0) {
    $.getJSON("/relatorios/grafico_avaliacao_mes", function (data) {
      $.each(data, function (index, item) {
        let item_data = item["data"];
        let items = Object.values(item_data);
        const color = Utils.namedColor(index);

        if ($.isEmptyObject(labels_chart)) {
          $.each(Object.keys(item_data), function (key, value) {
            labels_chart.push("" + value);
          });
        }

        if (item["name"] == "Avaliações") {
          datasets.push({
            label: item["name"],
            data: items,
            borderColor: Utils.CHART_COLORS.blue,
            backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0),
            order: index,
          });
        } else {
          datasets.push({
            label: item["name"],
            data: items,
            borderColor: color,
            backgroundColor: Utils.transparentize(color, 0),
            order: index,
          });
        }
      });

      const charts_data = {
        labels: labels_chart,
        datasets: datasets,
      };

      const config = {
        type: "bar",
        data: charts_data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: false,
            },
          },
        },
      };

      const myChart = new Chart(
        document.getElementById("evaluationMonthReports"),
        config
      );
    });
  }
};

evaluationMonthReports();
