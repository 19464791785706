export default {
  state: () => ({
    permited: false,
    kind: null,
    is_auction: false,
    origin_id: null,
    dealer_id: null,
    seller_id: null,
    engine_score: null,
    gearbox_score: null,
    final_judgment: null,
    note: null,
    value: null,
    value_addition: null,
    value_bonus: null,
    trading_value: null,
    note_id: null,
    destination: null,
  }),
  mutations: {
    setIsHourAuction(state, payload) {
      state.is_auction = payload;
    },
    setDestination(state, payload) {
      state.destination = payload;
    },
    setValue(state, payload) {
      state.value = payload;
    },
    setValueAddition(state, payload) {
      state.value_addition = payload;
    },
    setValueBonus(state, payload) {
      state.value_bonus = payload;
    },
    setTradingValue(state, payload) {
      state.trading_value = payload;
    },
    setPermited(state, payload) {
      state.permited = payload;
    },
    setKind(state, payload) {
      state.kind = payload;
    },
    setOriginId(state, payload) {
      state.origin_id = payload;
    },
    setDealerId(state, payload) {
      state.dealer_id = payload;
    },
    setSellerId(state, payload) {
      state.seller_id = payload;
    },
    setEngineScore(state, payload) {
      state.engine_score = payload;
    },
    setGearboxScore(state, payload) {
      state.gearbox_score = payload;
    },
    setFinalJudgment(state, payload) {
      state.final_judgment = payload;
    },
    setNote(state, payload) {
      state.note = payload;
    },
    setNoteId(state, payload) {
      state.note_id = payload;
    },
    setEvaluationForm(state, payload) {
      state.permited = true;
      state.kind = payload.kind;
      state.origin_id = payload.origin_id;
      state.dealer_id = payload.dealer_id;
      state.seller_id = payload.seller_id;
      state.engine_score = payload.engine_score;
      state.is_auction = payload.is_auction;
      state.gearbox_score = payload.gearbox_score;
      state.final_judgment = payload.final_judgment;
      state.note = payload.note;
      state.note_id = payload.note_id;

      if (payload.destination) {
        state.destination = payload.destination;
      }

      if (payload.trading_value) {
        state.trading_value = payload.trading_value;
      }

      if (payload.value) {
        state.value = payload.value;
      }

      if (payload.value_addition) {
        state.value_addition = payload.value_addition;
      }

      if (payload.value_bonus) {
        state.value_bonus = payload.value_bonus;
      }
    },
  },
  actions: {},
};
