import { domReady } from "../utils";

const togglePassword = () => {
  const targetTogglePassword = document.querySelector("#togglePasswordBtn");
  const passwordField = document.querySelector("#user_password");
  const icon = document.querySelector("#togglePasswordBtn .mdi");

  if (targetTogglePassword) {
    targetTogglePassword.addEventListener("click", function(event) {
      event.preventDefault();

      if (passwordField.getAttribute("type") == "password") {
        passwordField.setAttribute("type", "text");
        icon.classList.remove("mdi-eye-off");
        icon.classList.add("mdi-eye");
      } else {
        passwordField.setAttribute("type", "password");
        icon.classList.remove("mdi-eye");
        icon.classList.add("mdi-eye-off");
      }
    });
  }
};

export default domReady(togglePassword);
