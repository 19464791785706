import PerfectScrollbar from "perfect-scrollbar";
import { domReady } from "../utils";

const initPerfectScrollbar = () => {
  // const container = document.querySelector(".container-scroller");
  // if (container) {
  //   new PerfectScrollbar(container, {
  //     wheelSpeed: 2,
  //     wheelPropagation: true,
  //     minScrollbarLength: 20
  //   });
  // }
};

export default domReady(initPerfectScrollbar);
